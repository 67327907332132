import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import Navbar from 'components/templates/Navbar';
import Footer from 'components/templates/Footer';
import Checkbox from 'components/atoms/Checkbox';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { BiSearch } from 'react-icons/bi';
import DaumPostcode from 'react-daum-postcode';
import SelectOptions from 'components/atoms/SelectOptions';
import { useNavigate, useParams } from "react-router-dom";

import { observer } from 'mobx-react';
import stores from 'stores';

const Recruitment = observer(() => {
  const { overseasStore } = stores;
  const navigate = useNavigate();

  let { resumeId } = useParams();
  //   const [tab, setTab] = useState('공장');

  //   const handleTab = (x) => {
  //     setTab(x);
  //   };
  useEffect(() => {
    if (localStorage.getItem('role') !== 'EMPLOYER') {
      navigate('/login')
    }
  }, [])

  const [othersInputChecked, setOthersInputChecked] = useState(false);
  const handleOthersInputChecked = () => {
    setOthersInputChecked(!othersInputChecked);
  };

  const [consultationTimeChecked, setConsultationTimeChecked] = useState(false);
  const handleChangeConsultationTime = () => {
    setConsultationTimeChecked(!consultationTimeChecked);
  };

  const [imageName, setImageName] = useState('');

  const [inputs, setInputs] = useState({
    title: '',
    companyName: '',
    field: '',
    contact: '',
    address: '',
    addressEng: '',
    detailAddress: '',
    pay: '',
    text: '',
    businessName: '',
    idNumFront: '',
    idNumBack: '',
    representative: '',
    zipcode: '',
    sigunguCode: '',
    businessCertificateFileUrl: '',
  });

  const {
    title,
    companyName,
    contact,
    field,
    address,
    addressEng,
    pay,
    detailAddress,
    text,
    businessName,
    idNumFront,
    idNumBack,
    representative,
    zipcode,
    sigunguCode,
    businessCertificateFileUrl,
  } = inputs;

  const onChange = (e) => {
    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const onBirthChange = (e) => {
    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: value.length > 6? value.substring(0, 6): value,
    });
  }

  const onPayChange = (e) => {
    const { value, name } = e.target;
    let formatted = `${parseInt(value.replace(/\D/g, "")).toLocaleString()}`;
    
    setInputs({
      ...inputs,
      [name]: formatted,
    });
  }

  const [payStandard, setPayStandard] = useState({ value: '', text: '선택' });
  const payStandardOption = [
    { value: 'HOURLY', text: '시급' },
    { value: 'DAILY', text: '일급' },
    { value: 'WEEKLY', text: '주급' },
    { value: 'MONTHLY', text: '월급' },
    { value: 'YEARLY', text: '연봉' },
  ];

  const handlePayStandardChange = (e) => {
    setPayStandard(e.target.value);
    console.log(e.target.value);
  };

  const [showPostCode, setShowPostCode] = useState(false);

  const handleAdress = (data) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }
    console.log(data);
    setInputs({
      ...inputs,
      address: fullAddress,
      zipcode: data.zonecode,
      addressEng: data.addressEnglish,
      sigunguCode: data.sigunguCode,
    });
    setShowPostCode(false);
  };

  const postCodeStyle = {
    display: 'block',
    position: 'fixed',
    top: '20%',
    left: '30%',
    width: '700px',
    height: '400px',
    zIndex: 100,
    border: '2px solid #bcbcbc',
  };

  const timeOption = [
    { value: 'MORNING', text: '오전' },
    { value: 'AFTERNOON', text: '오후' },
  ];

  const hourOption = [
    { value: '01:00', text: '01:00' },
    { value: '02:00', text: '02:00' },
    { value: '03:00', text: '03:00' },
    { value: '04:00', text: '04:00' },
    { value: '05:00', text: '05:00' },
    { value: '06:00', text: '06:00' },
    { value: '07:00', text: '07:00' },
    { value: '08:00', text: '08:00' },
    { value: '09:00', text: '09:00' },
    { value: '10:00', text: '10:00' },
    { value: '11:00', text: '11:00' },
    { value: '12:00', text: '12:00' },
  ];

  const [startHour, setStartHour] = useState({ value: '01:00', text: '01:00' });
  const [endTime, setEndTime] = useState({ value: 'MORNING', text: '오전' });
  const [endHour, setEndHour] = useState({ value: '01:00', text: '01:00' });
  const periodOption = [
    { value: 'UNDER_WEEK', text: '1주일 이하' },
    { value: 'UNDER_MONTH', text: '1개월 이하' },
    { value: 'UNDER_3MONTH', text: '3개월 이하' },
    { value: 'UNDER_6MONTH', text: '6개월 이하' },
    { value: 'UNDER_1YEAR', text: '1년 이하' },
    { value: 'ABOVE_1YEAR', text: '1년 이상' },
  ];
  const [period, setPeriod] = useState({ value: '', text: '기간 선택' });
  const dayOpion = [
    { value: 1, text: '주 1일' },
    { value: 2, text: '주 2일' },
    { value: 3, text: '주 3일' },
    { value: 4, text: '주 4일' },
    { value: 5, text: '주 5일' },
    { value: 6, text: '주 6일' },
    { value: 7, text: '주 7일' },
  ];
  const [day, setDay] = useState({ value: 0, text: '요일 선택' });
  const [startTime, setStartTime] = useState({ value: 'AFTERNOON', text: '오후' });

  const [Image, setImage] = useState('');
  const fileInput = useRef(null);

  const handleChangeImage = async (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      let formData = new FormData();
      formData.append('file', e.target.files[0]);
      formData.append('type', 'global/');
      await overseasStore.postFile(formData).then((res) => {
        console.log(res);
        setInputs({
          ...inputs,
          ['businessCertificateFileUrl']: res.data.data,
        });
        setImageName(e.target.files[0].name);
      })
      .catch((error) => {
        alert('파일 업로드에 실패했습니다.')
        console.log(error);
      });
    } else {
      //업로드 취소할 시
      setImage('');
      return;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!address || !zipcode || !detailAddress || !businessCertificateFileUrl || !endHour ||
      !startHour || !businessName || !companyName || !contact || !text || !field || !dayOpion || 
      !pay || !payStandard || !period || !representative || !idNumFront || !idNumBack || !addressEng ||
      !sigunguCode || !title) {
        alert('필수 값을 입력해 주세요.');
        return;
      }
    const data = {
      address: address,
      addressCode: zipcode,
      addressDetails: detailAddress,
      businessCertificateFileUrl: businessCertificateFileUrl,
      businessHourEnd: endHour.value,
      businessHourNegotiable: consultationTimeChecked,
      businessHourStart: startHour.value,
      businessName: businessName,
      companyName: companyName,
      contact: contact,
      details: text,
      jobPosition: field,
      numberOfWorkDays: dayOpion.value,
      payAmount: pay.replace(/\D/g, ""),
      payType: payStandard.value,
      periodType: period.value,
      personName: representative,
      personRegistrationNumber: idNumFront + idNumBack,
      resumeId: resumeId,
      roadAddressEnglish: addressEng,
      sigunguCode: sigunguCode,
      title: title,
    };
    console.log(data);
    overseasStore.postJobOffers(data);
  };

  return (
    <>
      <Navbar />
      <Wrap onSubmit={handleSubmit}>
        <Title>채용제안 보내기</Title>
        <FormWrap>
          <FormFirstSection>
            <InputWrap>
              <Label>공고 제목</Label>
              <InputLine
                placeholder="공고 제목 입력"
                name="title"
                onChange={onChange}
                value={title}
              />
            </InputWrap>
            <InputWrap>
              <Label>업체명</Label>
              <InputLine
                placeholder="업체명 입력"
                name="companyName"
                onChange={onChange}
                value={companyName}
              />
            </InputWrap>
            <InputWrap>
              <Label>연락처</Label>
              <InputLine
                placeholder="010-5258-1232"
                name="contact"
                onChange={onChange}
                value={contact}
              />
            </InputWrap>
            <InputTabWrap>
              <Label>모집 분야</Label>
              <InputLine
                placeholder="모집 분야를 작성해주세요"
                name="field"
                onChange={onChange}
                value={field}
              />
            </InputTabWrap>
          </FormFirstSection>
          <DivideBox></DivideBox>
          <FormSecondSection>
            <SecondSectionLeft>
              <LabelBox>
                <AdditionalLabelTitle>급여</AdditionalLabelTitle>
                <AdditionalLabel>
                  *2022년 최저시급은 <span>9,160원</span>입니다.
                </AdditionalLabel>
              </LabelBox>
              <SelectInputWrap>
                <SelectWrap>
                  <SelectOptions
                    value={payStandard}
                    onSelect={(val) => setPayStandard(val)}
                    options={payStandardOption}
                    style={{ height: '40px' }}
                    line={true}
                  />
                </SelectWrap>
                <InputLineMoney placeholder="금액입력" value={pay} name="pay" onChange={onPayChange} />
                <InputSubText>원</InputSubText>
              </SelectInputWrap>
              <InputBoxWrap>
                <Label>근무 장소</Label>
                <AddressInputWrap onClick={() => setShowPostCode(!showPostCode)}>
                  <InputBox
                    placeholder="지번, 도로명, 건물명을 입력하세요."
                    defaultValue={address}
                    disabled
                  />
                  <SearchIcon />
                </AddressInputWrap>
                {showPostCode ? (
                  <div>
                    <DaumPostcode onComplete={handleAdress} style={postCodeStyle} />
                  </div>
                ) : (
                  <></>
                )}
                <InputBox
                  placeholder="상세 주소를 입력하세요."
                  value={detailAddress}
                  onChange={onChange}
                  name="detailAddress"
                />
              </InputBoxWrap>
            </SecondSectionLeft>
            <SecondSectionRight>
              <Label>근무 시간</Label>
              <TimeWrap>
                <StartTime>
                  <TimeSelectWrap>
                    <SelectOptions
                      value={startTime}
                      onSelect={(val) => setStartTime(val)}
                      options={timeOption}
                      style={{ height: '40px' }}
                      line={true}
                    />
                  </TimeSelectWrap>
                  <HourSelectWrap>
                    <SelectOptions
                      expand={true}
                      value={startHour}
                      onSelect={(val) => setStartHour(val)}
                      options={hourOption}
                      style={{ height: '40px' }}
                      line={true}
                    />
                  </HourSelectWrap>
                </StartTime>
                <InputSubText>~</InputSubText>
                <EndTime>
                  <TimeSelectWrap>
                    <SelectOptions
                      value={endTime}
                      onSelect={(val) => setEndTime(val)}
                      options={timeOption}
                      style={{ height: '40px' }}
                      line={true}
                    />
                  </TimeSelectWrap>
                  <HourSelectWrap>
                    <SelectOptions
                      expand={true}
                      value={endHour}
                      onSelect={(val) => setEndHour(val)}
                      options={hourOption}
                      style={{ height: '40px' }}
                      line={true}
                    />
                  </HourSelectWrap>
                </EndTime>
              </TimeWrap>
              <ConsultationWrap>
                <Checkbox
                  type="checkbox"
                  name="budgetCheck"
                  handleClick={handleChangeConsultationTime}
                  isChecked={consultationTimeChecked}
                >
                  근무시간 협의 가능
                </Checkbox>
              </ConsultationWrap>
              <PeriodAndDay>
                <PeriodWrap>
                  <Label>근무 기간</Label>
                  <PeriodSelectWrap>
                    <SelectOptions
                      value={period}
                      onSelect={(val) => setPeriod(val)}
                      options={periodOption}
                      style={{ height: '40px' }}
                      line={true}
                    />
                  </PeriodSelectWrap>
                </PeriodWrap>
                <DayWrap>
                  <Label>근무 요일</Label>
                  <DaySelectWrap>
                    <SelectOptions
                      value={day}
                      onSelect={(val) => setDay(val)}
                      options={dayOpion}
                      style={{ height: '40px' }}
                      line={true}
                    />
                  </DaySelectWrap>
                </DayWrap>
              </PeriodAndDay>
            </SecondSectionRight>
          </FormSecondSection>
          <DivideBox></DivideBox>
          <FormThirdSection>
            <Label>상세 정보</Label>
            <Textarea
              value={text}
              name="text"
              onChange={onChange}
              placeholder={`예시 ) ○담당업무: 플라스틱용기(샴푸,화장품 용기) 포장,선별 투입(인쇄관련),조립(캡)등 생산관련 업무. \n○근무(급여)형태: *일당직11만(주3일근무), *주급직598,500원(시급9,500원/주5일근무/주휴수당 지급)\n○(급여)공제사항: 고용보험료 0.9%\n○휴게시간: 오전(10분),점심(40분),오후(10분), 오후 간식(20분)`}
            />
          </FormThirdSection>
        </FormWrap>
        <BusinessPartWrap>
          <Title>사업자 인증</Title>
          <FormWrap>
            <BusinessPartSection>
              <BusinessPartLeft>
                <BusinessPartInputBoxWrap>
                  <LabelBox>
                    <AdditionalLabelTitle>회사명</AdditionalLabelTitle>
                    <AdditionalLabel>
                      <span>*사업자등록증 사진과 동일하게 작성해주세요</span>
                    </AdditionalLabel>
                  </LabelBox>
                  <InputBox
                    placeholder="회사명을 입력하세요."
                    value={businessName}
                    onChange={onChange}
                    name="businessName"
                  />
                </BusinessPartInputBoxWrap>
                <BusinessPartInputBoxWrap>
                  <Label>대표자 생년월일</Label>
                  <IdNumWrap>
                    <IdNumInputBox type={'number'} maxLength="8" value={idNumFront} onChange={onBirthChange} name="idNumFront" />{' '}
                    <Dash></Dash>
                    <IdNumInputBoxBack
                      value={idNumBack}
                      onChange={onChange}
                      name="idNumBack"
                      maxLength="1"
                    />{' '}
                    <IdNumBackEncryption>******</IdNumBackEncryption>
                  </IdNumWrap>
                </BusinessPartInputBoxWrap>
                <BusinessPartInputBoxWrap>
                  <Label>사업자 등록증 사진</Label>
                  <PhotoUploadBtn
                    type="button"
                    onClick={() => {
                      fileInput.current.click();
                    }}
                  >
                    <PlusIcon>+</PlusIcon>
                    <p>사진 올리기</p>
                  </PhotoUploadBtn>
                  <FileName>{imageName}</FileName>
                  <ImgInput
                    type="file"
                    accept="image/jpg,image/png,image/jpeg"
                    name="profile_img"
                    onChange={handleChangeImage}
                    ref={fileInput}
                  />
                </BusinessPartInputBoxWrap>
              </BusinessPartLeft>
              <BusinessPartRight>
                <BusinessPartInputBoxWrap>
                  <LabelBox>
                    <AdditionalLabelTitle>대표자 성함</AdditionalLabelTitle>
                    <AdditionalLabel>
                      <span>*사업자등록증 사진과 동일하게 작성해 주세요.</span>
                    </AdditionalLabel>
                  </LabelBox>
                  <InputBox
                    placeholder="대표자 성함을 입력하세요."
                    value={representative}
                    onChange={onChange}
                    name="representative"
                  />
                </BusinessPartInputBoxWrap>
              </BusinessPartRight>
            </BusinessPartSection>
          </FormWrap>
        </BusinessPartWrap>
        <SubmitButton type="submit">채용제안 보내기</SubmitButton>
      </Wrap>
      <Footer />
    </>
  );
});

const Wrap = styled.form`
  margin: 80px auto 240px;
  width: 1100px;
`;

const Title = styled.p`
  font-size: 35px;
  letter-spacing: -1.75px;
  font-family: 'nexonBold';
  margin: 0 0 10px 10px; ;
`;

const FormWrap = styled.div`
  border: 2px solid ${theme.colors.border};
  border-radius: 10px;
`;

const FormFirstSection = styled.div`
  padding: 30px 0 60px 40px;
`;

const InputWrap = styled.div`
  display: flex;

  & + & {
    margin: 60px 0 0 0;
  }
`;

const FileName = styled.p`
  font-size: 18px;
  font-family: 'nexonRegular';
  margin-top: 8px;
`;

const Label = styled.p`
  font-size: 18px;
  font-family: 'nexonMedium';
  width: 180px;
  color: ${theme.colors.bodyText};
  margin: 8px 0 0 0;
`;

const InputLine = styled.input`
  border: 0;
  border-bottom: 2px solid #a2a5d2;
  font-size: 16px;
  font-family: 'nexonRegular';
  width: 470px;
  padding: 7px 0;

  &::placeholder {
    color: #d0d2e8;
  }
`;

const InputTabWrap = styled.div`
  margin: 60px 0 0 0;
  display: flex;
`;

const DivideBox = styled.div`
  background-color: #eef1f5;
  height: 15px;
`;

const FormSecondSection = styled.div`
  padding: 30px 0 60px 40px;
  display: flex;
`;

const SecondSectionLeft = styled.div`
  margin: 0 100px 0 0;
`;

const LabelBox = styled.div`
  display: flex;
`;

const AdditionalLabelTitle = styled(Label)`
  width: auto;
`;

const AdditionalLabel = styled.p`
  font-size: 14px;
  font-family: 'nexonRegular';
  margin: 9px 0 0 30px;

  & > span {
    color: ${theme.colors.primary};
  }
`;

const SelectInputWrap = styled.div`
  margin: 20px 0 0 0;
  display: flex;
`;

const SelectWrap = styled.div`
  width: 80px;
  position: relative;
`;

const Select = styled.select`
  width: 100px;
  border: 0;
  border-bottom: 2px solid #a2a5d2;
  font-size: 22px;
  color: #d0d2e8;
  padding: 5px 0;
  appearance: none;
  background: transparent;
  &:focus {
    outline: none;
  }
`;

const SelectIcon = styled(MdKeyboardArrowDown)`
  font-size: 30px;
  position: absolute;
  right: 0;
  top: 2px;
  color: #d0d2e8;
  z-index: -1;
`;

const InputLineMoney = styled(InputLine)`
  width: 210px;
  margin: 0 0 0 20px;
`;
const InputSubText = styled.p`
  font-size: 22px;
  color: ${theme.colors.bodyText};
  margin: 7px 0 0 0;
`;

const InputBoxWrap = styled.div`
  margin: 97px 0 0 0;
  display: block;
`;

const AddressInputWrap = styled.div`
  position: relative;
  display: inline-block;
  margin: 17px 0 0 0;
`;

const InputBox = styled.input`
  width: 463px;
  height: 56px;
  border: 2px solid #a2a5d2;
  margin: 10px 0 0 0;
  font-size: 18px;
  padding: 0 60px 0 19px;
  display: block;

  &::placeholder {
    color: #d0d2e8;
  }
`;

const SearchIcon = styled(BiSearch)`
  position: absolute;
  font-size: 35px;
  right: 10px;
  top: 21px;
  color: ${theme.colors.bodyText};
`;

const SecondSectionRight = styled.div``;

const TimeSelectWrap = styled(SelectWrap)`
  width: 74px;
`;

const TimeWrap = styled.div`
  margin: 20px 0 0 0;
  display: flex;
`;

const StartTime = styled.div`
  display: flex;
  margin: 0 20px 0 0;
`;

const TimeSelect = styled(Select)`
  width: 74px;
`;

const HourSelectWrap = styled(SelectWrap)`
  margin: 0 0 0 17px;
`;

const HourSelect = styled(Select)``;

const EndTime = styled.div`
  display: flex;
  margin: 0 0 0 20px;
`;

const ConsultationWrap = styled.div`
  margin: 20px 0 0 0;
`;

const PeriodAndDay = styled.div`
  margin: 68px 0 0 0;
  display: flex;
`;

const PeriodWrap = styled.div`
  margin: 0 60px 0 0;
`;

const PeriodSelectWrap = styled(SelectWrap)`
  width: 170px;
  margin: 35px 0 0 0;
`;

const PeriodSelect = styled(Select)`
  width: 215px;
`;

const DayWrap = styled.div``;

const DaySelectWrap = styled(SelectWrap)`
  width: 170px;
  margin: 35px 0 0 0;
`;

const DaySelect = styled(Select)`
  width: 215px;
`;

const FormThirdSection = styled.div`
  padding: 30px 0 60px 40px;
`;

const Textarea = styled.textarea`
  width: 1015px;
  height: 477px;
  border: 2px solid #a2a5d2;
  margin: 26px 0 0 0;
  font-family: 'nexonRegular';
  font-size: 18px;
  padding: 25px;
  line-height: 50px;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #d0d2e8;
  }
`;

const SubmitButton = styled.button`
  width: 339px;
  height: 62px;
  background-color: ${theme.colors.primary};
  color: white;
  border: 0;
  font-size: 20px;
  display: block;
  margin: 60px auto 0;
`;

const BusinessPartWrap = styled.div`
  margin: 70px 0 0 0;
`;

const BusinessPartLeft = styled.div``;

const BusinessPartSection = styled(FormSecondSection)``;

const IdNumWrap = styled.div`
  display: flex;
  align-items: center;
`;

const Dash = styled.div`
  height: 2px;
  width: 10px;
  background: #555666;
  margin: 0 15px;
`;

const IdNumInputBox = styled(InputBox)`
  width: 160px;
  padding: 0 19px 0 19px;
`;

const IdNumInputBoxBack = styled(InputBox)`
  width: 35px;
  padding: 0 5px 0 9px;
`;

const IdNumBackEncryption = styled.p`
  font-size: 26px;
  color: #555666;
  margin: 10px 0 0 10px;
`;

const BusinessPartInputBoxWrap = styled(InputBoxWrap)`
  margin: 0;

  & + & {
    margin: 97px 0 0 0;
  }
`;

const PhotoUploadBtn = styled.button`
  width: 167px;
  height: 43px;
  border-radius: 20px;
  border: 3px solid #d0d2e8;
  background-color: transparent;
  margin: 22px 0 0 0;
  font-size: 21px;
  font-family: 'nexonRegular';
  display: flex;
  justify-content: center;
  align-items: center;

  & > p {
    margin: 3px 0 0 0;
  }
`;

const PlusIcon = styled.div`
  font-size: 30px;
  font-family: 'nexonBold';
  color: white;
  width: 25px;
  height: 25px;
  border-radius: 20px;
  background: ${theme.colors.primary};
  display: inline-block;
  line-height: 28px;
  margin: 0 5px 0 0;
`;

const ImgInput = styled.input`
  display: none;
`;

const BusinessPartRight = styled.div`
  margin: 0 0 0 57px;
`;

export default Recruitment;
