import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import Navbar from 'components/templates/Navbar';
import Footer from 'components/templates/Footer';
import SelectOptions from 'components/atoms/SelectOptions';
import { observer } from 'mobx-react';
import stores from 'stores';
import PopUp from 'components/atoms/PopUp';
import { useNavigate } from "react-router-dom";

import { BsFillPersonFill } from 'react-icons/bs';
import { get } from 'mobx';

const ResumeRegistration = observer(() => {
  const { fileStore, overseasStore, counrtyStore } = stores;
  const navigate = useNavigate();

  const resumeId = useRef();
  useEffect(() => {
    if (localStorage.getItem('role') !== 'EMPLOYEE') {
      navigate('/login')
    }
  }, [])

  useEffect(() => {
    counrtyStore.getCountries();
  }, [counrtyStore]);

  useEffect(() => {
    overseasStore.getMyResume()
    .then((res) => {
      if (res.data.responseCode === 'SUCCESS') {
        console.log(res.data.data);
        let data = res.data.data;
        resumeId.current = data.id;
        if (data.gender === 'MAN') {
          setGender({ value: 'MAN', text: '남성' });
        } else if (data.gender === 'WOMAN') {
          setGender({ value: 'WOMAN', text: '여성' });
        }
        setInputs({
          name: data.name,
          birth: data.birthDate,
          email: data.email,
          academicName: '',
          academicCountry: '',
          academicMajor: '',
          contact: data.cellphone,
          hopeWork: '',
          hopeArea: '',
          hopeSalary: data.expectedPayAmount? data.expectedPayAmount.toLocaleString(): '',
          text: data.introduction,
          careerCompany: '',
          careerLank: '',
          careerDetails: '',
          koreanSkillRating: data.koreanCapabilityGrade? `${data.koreanCapabilityGrade}급`:'',
        });
        setCountryInfo(data.country);
        if (data.koreanCapability) {
          for (let optionItem in koreanSkillOption) {
            if (koreanSkillOption[optionItem].value === data.koreanCapability) {
              setKoreanSkill(koreanSkillOption[optionItem]);
            }
          }
        }
        if (data.expectedJobs) {
          setHopeWorkInputs(data.expectedJobs);
        }
        if (data.academicList) {
          for (let index in data.academicList) {
            for (let optionItem in educationOption) {
              if (educationOption[optionItem].value === data.academicList[index].academicBackgroundType) {
                data.academicList[index].academicBackgroundType = educationOption[optionItem];
              }
            }
          }
          setAcademicList(data.academicList);
        }
        if (data.careerList) {
          for (let index in data.careerList) {
            for (let optionItem in careerOption) {
              if (careerOption[optionItem].value === data.careerList[index].workExperienceType) {
                data.careerList[index].workExperienceType = careerOption[optionItem];
              }
            }
          }
          setCareerList(data.careerList);
        }
        if (data.koreanCapabilityCertificateUrl) {
          setKoreanSkillImageUrl(data.koreanCapabilityCertificateUrl);
        }
        if (data.profileImageUrl) {
          setImage(data.profileImageUrl);
          console.log('image', data.profileImageUrl);
        }
      }
    })
    .catch((error) => {
      console.log(error);
    });;
  }, []);

  const [inputs, setInputs] = useState({
    name: '',
    birth: '',
    email: '',
    academicName: '',
    academicCountry: '',
    academicMajor: '',
    contact: '',
    hopeWork: '',
    hopeArea: '',
    hopeSalary: '',
    text: '',
    careerCompany: '',
    careerLank: '',
    careerDetails: '',
    koreanSkillRating: '',
  });

  const {
    name,
    birth,
    email,
    contact,
    text,
    academicName,
    academicCountry,
    academicMajor,
    careerLank,
    careerCompany,
    careerDetails,
    koreanSkillRating,
    hopeSalary,
  } = inputs;

  const onChange = (e) => {
    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const onBirthChange = (e) => {
    const { value, name } = e.target;
    // console.log(value, name);
    let birthFormatted = '';
    let onlyNumber = value.replaceAll('-', '');
    if (onlyNumber.length > 6) {
      birthFormatted = `${onlyNumber.substring(0, 4)}-${onlyNumber.substring(4, 6)}-${onlyNumber.substring(6, 8)}`
    } else if (onlyNumber.length > 4) {
      birthFormatted = `${onlyNumber.substring(0, 4)}-${onlyNumber.substring(4, 6)}`;
    } else {
      birthFormatted = onlyNumber.substring(0, 4);
    }
    setInputs({
      ...inputs,
      [name]: birthFormatted,
    });
  }

  const onKoreanSkillRatingChange = (e) => {
    const { value, name } = e.target;
    let formatted = `${value.replace(/\D/g, "")}급`;
    
    setInputs({
      ...inputs,
      [name]: formatted,
    });
  }

  const onHopeSalaryChange = (e) => {
    const { value, name } = e.target;
    let formatted = `${parseInt(value.replace(/\D/g, "")).toLocaleString()}`;
    
    setInputs({
      ...inputs,
      [name]: formatted,
    });
  }

  const [image, setImage] = useState('');
  const fileInput = useRef(null);
  const [profileImgUrl, setProfileImageUrl] = useState('');

  const handleChangeImage = (e) => {
    const uploadImage = e.target.files[0];
    if (uploadImage) {
      const formData = new FormData();
      formData.append('file', uploadImage);
      formData.append('type', 'overseaResume/');
      fileStore
        .updateFile(formData)
        .then((res) => {
          setProfileImageUrl(res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
      setImage(uploadImage);
    } else {
      //업로드 취소할 시
      setImage('');
      return;
    }
    //화면에 프로필 사진 표시
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImage(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const [education, setEducation] = useState({ value: '', text: '선택' });
  const educationOption = [
    { value: 'HIGH_SCHOOL_GRADUATE', text: '고등학교 졸업' },
    { value: 'KOREA_COLLEGE_GRADUATE', text: '국내 대학 졸업' },
    { value: 'COLLEGE_GRADUATE', text: '대학교 졸업' },
    { value: 'TOP_50_COLLEGE_GRADUATE', text: '탑 50대학 졸업' },
    { value: 'MASTER', text: '석사' },
    { value: 'DOCTOR', text: '박사' },
  ];

  const [koreanSkill, setKoreanSkill] = useState({ value: '', text: '선택' });
  const koreanSkillOption = [
    { value: 'VERY_BAD', text: '최하' },
    { value: 'BAD', text: '하' },
    { value: 'NORMAL', text: '보통' },
    { value: 'GOOD', text: '상' },
    { value: 'VERY_GOOD', text: '최상' },
  ];

  const [career, setCareer] = useState({ value: '', text: '선택' });
  const careerOption = [
    { value: 'ONE', text: '경력 1년' },
    { value: 'TWO', text: '경력 2년' },
    { value: 'THREE', text: '경력 3년' },
    { value: 'FIVE', text: '경력 5년' },
    { value: 'EIGHT', text: '경력 8년' },
    { value: 'TEN', text: '경력 10년' },
    { value: 'TEN_ABOVE', text: '경력 10년 이상' },
  ];

  const [gender, setGender] = useState({ value: 'NONE', text: '선택' });
  const genderOption = [
    { value: 'MAN', text: '남성' },
    { value: 'WOMAN', text: '여성' },
  ];

  const [educationImage, setEducationImage] = useState(null);
  const [educationImageUrl, setEducationImageUrl] = useState('');
  const educationImageRef = useRef();

  const handleChangeEducationImage = (e) => {
    const uploadImage = e.target.files[0];
    if (uploadImage) {
      const formData = new FormData();
      formData.append('file', uploadImage);
      formData.append('type', 'overseaResume/');
      console.log(uploadImage);
      fileStore
        .updateFile(formData)
        .then((res) => {
          setEducationImageUrl(res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
      setEducationImage(uploadImage);
    } else {
      //업로드 취소할 시
      setEducationImage('');
      return;
    }
  };

  const [careerImage, setCareerImage] = useState('');
  const [careerImageUrl, setCareerImageUrl] = useState('');
  const careerFileInput = useRef(null);

  const handleChangeCareerImage = (e) => {
    const uploadImage = e.target.files[0];
    if (uploadImage) {
      const formData = new FormData();
      formData.append('file', uploadImage);
      formData.append('type', 'overseaResume/');
      console.log(uploadImage);
      fileStore
        .updateFile(formData)
        .then((res) => {
          setCareerImageUrl(res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
      setCareerImage(uploadImage);
    } else {
      //업로드 취소할 시
      setCareerImage('');
      return;
    }
  };

  const [koreanSkillImage, setKoreanSkillImage] = useState('');
  const [koreanSkillImageUrl, setKoreanSkillImageUrl] = useState('');
  const koreanSkillFileInput = useRef(null);

  const handleChangeKoreanSkillImage = (e) => {
    const uploadImage = e.target.files[0];
    if (uploadImage) {
      const formData = new FormData();
      formData.append('file', uploadImage);
      formData.append('type', 'overseaResume/');
      console.log(uploadImage);
      fileStore
        .updateFile(formData)
        .then((res) => {
          setKoreanSkillImageUrl(res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
      setKoreanSkillImage(e.target.files[0]);
    } else {
      //업로드 취소할 시
      setKoreanSkillImage('');
      return;
    }
  };

  const [hopeWorkInputs, setHopeWorkInputs] = useState(['']);

  const addHopeWorkInputs = () => {
    setHopeWorkInputs(hopeWorkInputs.concat(''));
  };

  const [academicList, setAcademicList] = useState([]);

  const addAcademicList = () => {
    if (!education || !academicCountry || !academicMajor || !academicName) {
      return;
    }
    const item = {
      id: academicList.length,
      academicBackgroundType: education,
      academicCertificateName: educationImage,
      academicCertificateUrl: educationImageUrl,
      academicCountry: academicCountry,
      academicMajor: academicMajor,
      academicName: academicName,
    };
    setAcademicList([...academicList, item]);
    setInputs({ ...inputs, academicName: '', academicCountry: '', academicMajor: '' });
    setEducation({ value: '', text: '선택' });
    setEducationImageUrl('');
    setEducationImage(null);
  };

  const handleRemoveAcademicItem = (id) => {
    setAcademicList((academicList) => academicList.filter((item) => item.id !== id));
  };

  const [careerList, setCareerList] = useState([]);

  const addCareerList = () => {
    if (!careerCompany || !careerLank || !career) {
      return;
    }
    const item = {
      id: careerList.length,
      careerCertificateName: careerImage,
      careerCertificateUrl: careerImageUrl,
      careerDetails: careerDetails,
      companyName: careerCompany,
      position: careerLank,
      workExperienceType: career,
    };
    setCareerList([...careerList, item]);
    setInputs({
      ...inputs,
      careerCompany: '',
      careerLank: '',
      careerDetails: '',
    });
    setCareerImage(null);
    setCareerImageUrl('');
    setCareer({ value: '', text: '선택' });
  };

  const handleRemoveCareerItem = (id) => {
    setCareerList((careerList) => careerList.filter((item) => item.id !== id));
  };

  const [countryInfo, setCountryInfo] = useState(null);

  const handleCountryTab = (tab) => {
    setCountryInfo(tab);
  };

  const [popupVisible, setPopupVisible] = useState(false);
  const [countryPopupVisible, setCountryPopupVisible] = useState(false);
  const [imagePopupVisible, setImagePopupVisible] = useState(false);
  const [popupPhoto, setPopupPhoto] = useState('');

  const handleOpenImagePopup = () => {
    setImagePopupVisible(true);
    setPopupVisible(true);
  };

  const handleOpenCoutryPopup = () => {
    setCountryPopupVisible(true);
    setPopupVisible(true);
  };

  const handleClosePopup = () => {
    setImagePopupVisible(false);
    setCountryPopupVisible(false);
    setPopupVisible(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!birth || !contact || !email || !countryInfo || !email || hopeWorkInputs.length === 0
      || !hopeSalary || !gender || !text || !name) {
      alert('필수 값을 입력해 주세요.');
      return;
    }

    let careerListArr = [];
    for (let i = 0; i < careerList.length; i++) {
      careerListArr.push({
        careerCertificateUrl: careerList[i].careerCertificateUrl,
        careerDetails: careerList[i].careerDetails,
        companyName: careerList[i].companyName,
        position: careerList[i].position,
        workExperienceType: careerList[i].workExperienceType.value,
      });
    }
    if (careerDetails && careerCompany && careerLank && career) {
      careerListArr.push(
        {
          careerCertificateName: careerImage,
          careerCertificateUrl: careerImageUrl,
          careerDetails: careerDetails,
          companyName: careerCompany,
          position: careerLank,
          workExperienceType: career.value,
        }
      );
    }
    let academicListArr = [];
    for (let i = 0; i < academicList.length; i++) {
      academicListArr.push({
        academicCertificateUrl: academicList[i].academicCertificateUrl,
        academicCountry: academicList[i].academicCountry,
        academicMajor: academicList[i].academicMajor,
        academicName: academicList[i].academicName,
        academicBackgroundType: academicList[i].academicBackgroundType.value,
      });
    }
    if (education && academicCountry && academicMajor && academicName) {
      academicListArr.push(
        { 
          academicBackgroundType: education.value,
          academicCertificateName: educationImage,
          academicCertificateUrl: educationImageUrl,
          academicCountry: academicCountry,
          academicMajor: academicMajor,
          academicName: academicName,
        }
      );
    }

    const data = {
      academicList: academicListArr,
      birthDate: birth,
      careerList: careerListArr,
      cellphone: contact,
      countryEtcText: countryInfo.name,
      countyIsoCode: countryInfo.countryIsoCode,
      email: email,
      expectedJobs: hopeWorkInputs,
      expectedPayAmount: parseInt(hopeSalary.replace(/\D/g, "")),
      gender: gender.value,
      introduction: text,
      koreanCapability: koreanSkill.value,
      koreanCapabilityCertificateUrl: koreanSkillImageUrl,
      koreanCapabilityGrade: koreanSkillRating.replace(/\D/g, ""),
      name: name,
      profileImageUrl: profileImgUrl,
    };

    console.log(data);
    
    overseasStore.postResume(data);
  };

  return (
    <>
      <Navbar />
      <Wrap onSubmit={handleSubmit}>
        <Title>이력서 등록</Title>
        <ResumeRegiWrap>
          <FirstSection>
            <PersonalInfo>
              <PhotoWrap>
                <Label>증명 사진</Label>
                <Photo>
                  {image ? (
                    <Img
                      src={image}
                      onClick={() => {
                        fileInput.current.click();
                      }}
                    />
                  ) : (
                    <ImgIcon
                      onClick={() => {
                        fileInput.current.click();
                      }}
                    />
                  )}
                  <ImgInput
                    type="file"
                    accept="image/jpg,image/png,image/jpeg"
                    onChange={handleChangeImage}
                    ref={fileInput}
                  />
                </Photo>
              </PhotoWrap>
              <InfoBoxWrap>
                <InfoBox>
                  <InfoTitle>이름</InfoTitle>
                  <InfoInput placeholder="이름" name="name" value={name} onChange={onChange} />
                </InfoBox>
                <InfoBox>
                  <InfoTitle>국적</InfoTitle>
                  <CountryPopupButton onClick={handleOpenCoutryPopup} type="button">
                    {countryInfo ? (
                      <CountryActive>{countryInfo.name}</CountryActive>
                    ) : (
                      <CountryDisabled>태국</CountryDisabled>
                    )}
                  </CountryPopupButton>
                </InfoBox>
                <InfoBox>
                  <InfoTitle>성별</InfoTitle>
                  <GenderSelectOptionsInput>
                    <SelectOptions
                      value={gender}
                      onSelect={(val) => setGender(val)}
                      options={genderOption}
                      style={{ height: '33px' }}
                      line={true}
                    />
                  </GenderSelectOptionsInput>
                </InfoBox>
                <InfoBox>
                  <InfoTitle>생년월일</InfoTitle>
                  <InfoInput
                    placeholder="yyyy-mm-dd"
                    name="birth"
                    value={birth}
                    onChange={onBirthChange}
                  />
                </InfoBox>
                <InfoBox>
                  <InfoTitle>연락처</InfoTitle>
                  <InfoInput
                    placeholder="예) 010-0000-0000"
                    name="contact"
                    type={'number'}
                    value={contact}
                    onChange={onChange}
                  />
                </InfoBox>
                <InfoBox>
                  <InfoTitle>메일</InfoTitle>
                  <InfoInput
                    placeholder="예) employee@ko-life.co.kr"
                    name="email"
                    value={email}
                    onChange={onChange}
                  />
                </InfoBox>
              </InfoBoxWrap>
            </PersonalInfo>
          </FirstSection>
          <DivideBox></DivideBox>
          <SecondSection>
            <Label>이력사항</Label>
            {academicList &&
              academicList.map((item, index) => (
                <DisabledCareerInputWrap key={`academicList` + index}>
                  <DisabledCareerInputWrapFlex>
                    <CareerInputLeft>
                      <CareerBox>
                        <CareerItem>
                          <CareerTitle>학력</CareerTitle>
                          <LargeSelectedOptionWrap>
                            <InputDisabled>{item.academicBackgroundType.text}</InputDisabled>
                          </LargeSelectedOptionWrap>
                        </CareerItem>
                        <CareerItem>
                          <CareerTitle>학력 증명서</CareerTitle>
                          <CareerDataImage>
                            {item.academicCertificateUrl && <DisabledImgSrc onClick={() => {
                              setPopupPhoto(item.academicCertificateUrl)
                              handleOpenImagePopup();
                            }}>
                              확인하기
                            </DisabledImgSrc>}
                          </CareerDataImage>
                        </CareerItem>
                      </CareerBox>
                    </CareerInputLeft>
                    <CareerInputRight>
                      <CareerBox>
                        <CareerItem>
                          <CareerTitle>학교명</CareerTitle>
                          <DisabledCareerDataBox>{item.academicName}</DisabledCareerDataBox>
                        </CareerItem>
                        <CareerItem>
                          <CareerTitle>소재지</CareerTitle>
                          <DisabledCareerDataBoxSmall>
                            {item.academicCountry}
                          </DisabledCareerDataBoxSmall>
                          <CareerTitleMiddle>학과</CareerTitleMiddle>
                          <DisabledCareerDataBoxSmall>
                            {item.academicMajor}
                          </DisabledCareerDataBoxSmall>
                        </CareerItem>
                      </CareerBox>
                    </CareerInputRight>
                  </DisabledCareerInputWrapFlex>
                  <ButtonFlexWrap>
                    <DeleteButton type="button" onClick={() => handleRemoveAcademicItem(item.id)}>
                      삭제
                    </DeleteButton>
                  </ButtonFlexWrap>
                </DisabledCareerInputWrap>
              ))}
            <CareerInputWrap>
              <CareerInputLeft>
                <CareerBox>
                  <CareerItem>
                    <CareerTitle>학력</CareerTitle>
                    <LargeSelectedOptionWrap>
                      <SelectOptions
                        value={education}
                        onSelect={(val) => setEducation(val)}
                        options={educationOption}
                        style={{ height: '57px' }}
                        border={true}
                      />
                    </LargeSelectedOptionWrap>
                  </CareerItem>
                  <CareerItem>
                    <CareerTitle>학력 증명서</CareerTitle>
                    <CareerDataImage>
                      <ImageSrc>
                        {educationImage ? educationImage.name : '증명서 파일'}
                      </ImageSrc>
                      <ImageShowButton
                        onClick={() => {
                          educationImageRef.current.click();
                        }}
                        type="button"
                      >
                        업로드
                      </ImageShowButton>
                      <ImgInput
                        type="file"
                        onChange={handleChangeEducationImage}
                        ref={educationImageRef}
                      />
                    </CareerDataImage>
                  </CareerItem>
                </CareerBox>
              </CareerInputLeft>
              <CareerInputRight>
                <CareerBox>
                  <CareerItem>
                    <CareerTitle>학교명</CareerTitle>
                    <CareerDataBox
                      placeholder="예) 한국 대학교"
                      name="academicName"
                      onChange={onChange}
                      value={academicName || ''}
                    />
                  </CareerItem>
                  <CareerItem>
                    <CareerTitle>소재지</CareerTitle>
                    <CareerDataBoxSmall
                      placeholder="예) 태국"
                      name="academicCountry"
                      onChange={onChange}
                      value={academicCountry || ''}
                    />
                    <CareerTitleMiddle>학과</CareerTitleMiddle>
                    <CareerDataBoxSmall
                      placeholder="예) 컴퓨터 공학과"
                      name="academicMajor"
                      onChange={onChange}
                      value={academicMajor || ''}
                    />
                  </CareerItem>
                </CareerBox>
              </CareerInputRight>
            </CareerInputWrap>
            <PlusButton type="button" onClick={() => addAcademicList()}>
              <p>+</p>
            </PlusButton>
            {careerList &&
              careerList.map((item, index) => (
                <DisabledCareerInputWrap key={`careerList` + index}>
                  <DisabledCareerInputWrapFlex>
                    <CareerInputLeft>
                      <CareerBox>
                        <CareerItem>
                          <CareerTitle>경력</CareerTitle>
                          <LargeSelectedOptionWrap>
                            <InputDisabled>{item.workExperienceType.text}</InputDisabled>
                          </LargeSelectedOptionWrap>
                        </CareerItem>
                        <CareerItem>
                          <CareerTitle>경력 증명서</CareerTitle>
                          <CareerDataImage>
                            {item.careerCertificateUrl && 
                            <DisabledImgSrc onClick={() => {
                              setPopupPhoto(item.careerCertificateUrl);
                              handleOpenImagePopup();;
                            }}>
                              확인하기
                            </DisabledImgSrc>}
                          </CareerDataImage>
                        </CareerItem>
                      </CareerBox>
                    </CareerInputLeft>
                    <CareerInputRight>
                      <CareerBox>
                        <CareerItem>
                          <CareerTitle>회사명</CareerTitle>
                          <DisabledCareerDataBoxSmall>
                            {item.companyName}
                          </DisabledCareerDataBoxSmall>
                          <CareerTitleMiddle>직급</CareerTitleMiddle>
                          <DisabledCareerDataBoxSmall>{item.position}</DisabledCareerDataBoxSmall>
                        </CareerItem>
                        <CareerItem>
                          <CareerTitle>상세업무</CareerTitle>
                          <DisabledCareerDataBox>{item.careerDetails}</DisabledCareerDataBox>
                        </CareerItem>
                      </CareerBox>
                    </CareerInputRight>
                  </DisabledCareerInputWrapFlex>
                  <ButtonFlexWrap>
                    <DeleteButton type="button" onClick={() => handleRemoveCareerItem(item.id)}>
                      삭제
                    </DeleteButton>
                  </ButtonFlexWrap>
                </DisabledCareerInputWrap>
              ))}
            <CareerInputWrap>
              <CareerInputLeft>
                <CareerBox>
                  <CareerItem>
                    <CareerTitle>경력</CareerTitle>
                    <LargeSelectedOptionWrap>
                      <SelectOptions
                        value={career}
                        onSelect={(val) => setCareer(val)}
                        options={careerOption}
                        style={{ height: '70px' }}
                        border={true}
                      />
                    </LargeSelectedOptionWrap>
                  </CareerItem>
                  <CareerItem>
                    <CareerTitle>경력 증명서</CareerTitle>
                    <CareerDataImage>
                      <ImageSrc>{careerImage ? careerImage.name : '경력 증명서 파일'}</ImageSrc>
                      <ImageShowButton
                        onClick={() => {
                          careerFileInput.current.click();
                        }}
                        type="button"
                      >
                        업로드
                      </ImageShowButton>
                      <ImgInput
                        type="file"
                        onChange={handleChangeCareerImage}
                        ref={careerFileInput}
                      />
                    </CareerDataImage>
                  </CareerItem>
                </CareerBox>
              </CareerInputLeft>
              <CareerInputRight>
                <CareerBox>
                  <CareerItem>
                    <CareerTitle>회사명</CareerTitle>
                    <CareerDataBoxSmall
                      placeholder="예) 코라이프"
                      name="careerCompany"
                      onChange={onChange}
                      value={careerCompany || ''}
                    />
                    <CareerTitleMiddle>직급</CareerTitleMiddle>
                    <CareerDataBoxSmall
                      placeholder="예) 직원"
                      name="careerLank"
                      onChange={onChange}
                      value={careerLank || ''}
                    />
                  </CareerItem>
                  <CareerItem>
                    <CareerTitle>상세업무</CareerTitle>
                    <CareerDataBox
                      placeholder="예) 웹개발, 앱개발"
                      name="careerDetails"
                      onChange={onChange}
                      value={careerDetails || ''}
                    />
                  </CareerItem>
                </CareerBox>
              </CareerInputRight>
            </CareerInputWrap>
            <PlusButton type="button" onClick={() => addCareerList()}>
              <p>+</p>
            </PlusButton>
            <CareerInputWrap>
              <CareerInputLeft>
                <CareerBox>
                  <CareerItem>
                    <CareerTitle>
                      한국어
                      <br />
                      능력
                    </CareerTitle>
                    <KoreanSkillWrap>
                      <SelectOptions
                        value={koreanSkill}
                        onSelect={(val) => setKoreanSkill(val)}
                        options={koreanSkillOption}
                        style={{ height: '57px' }}
                        border={true}
                      />
                    </KoreanSkillWrap>
                    <CareerTitleMiddle>급수</CareerTitleMiddle>
                    <CareerDataBoxSmall
                      placeholder="예) 5급"
                      name="koreanSkillRating"
                      onChange={onKoreanSkillRatingChange}
                      value={koreanSkillRating || ''}
                    />
                  </CareerItem>
                </CareerBox>
              </CareerInputLeft>
              <CareerInputRight>
                <CareerBox>
                  <CareerItem>
                    <CareerTitle>한국어 능력 시험</CareerTitle>
                    <CareerDataImage>
                      <ImageSrc>
                        {koreanSkillImageUrl && 
                            <DisabledImgSrc onClick={() => {
                              setPopupPhoto(koreanSkillImageUrl)
                              handleOpenImagePopup();
                            }}>
                              확인하기
                        </DisabledImgSrc>}
                      </ImageSrc>
                      <ImageShowButton
                        type="button"
                        onClick={() => {
                          koreanSkillFileInput.current.click();
                        }}
                      >
                        업로드
                      </ImageShowButton>
                      <ImgInput
                        type="file"
                        onChange={handleChangeKoreanSkillImage}
                        ref={koreanSkillFileInput}
                      />
                    </CareerDataImage>
                  </CareerItem>
                </CareerBox>
              </CareerInputRight>
            </CareerInputWrap>
            <CareerHopeInputWrap>
              <CareerHopeItem>
                <CareerTitle>희망업무</CareerTitle>
                <CareerHopeBoxWrap>
                  {hopeWorkInputs?.map((value, index) => (
                    <CareerHopeDataBox
                      placeholder="예) 개발자"
                      key={index}
                      onChange={(e) =>
                        setHopeWorkInputs(
                          hopeWorkInputs.map((value, j) => {
                            if (index === j) value = e.target.value;
                            return value;
                          }),
                        )
                      }
                      value={value}
                    />
                  ))}
                  <PlusButtonSmall onClick={addHopeWorkInputs} type="button">
                    <p>+</p>
                  </PlusButtonSmall>
                </CareerHopeBoxWrap>
              </CareerHopeItem>
            </CareerHopeInputWrap>
            <CareerHopeInputWrap>
              <CareerBox>
                <CareerItem>
                  <CareerTitle>희망 연봉</CareerTitle>
                  <CareerDataBox
                    placeholder="예) 25,000,000"
                    name="hopeSalary"
                    onChange={onHopeSalaryChange}
                    value={hopeSalary || ''}
                  />
                  <MoneySymbol>₩</MoneySymbol>
                </CareerItem>
              </CareerBox>
            </CareerHopeInputWrap>
          </SecondSection>
          <DivideBox></DivideBox>
          <ThirdSection>
            <SelfIntroWrap>
              <Label>자기소개</Label>
              <Textarea
                placeholder="예) 성실히 근무하겠습니다."
                name="text"
                value={text || ''}
                onChange={onChange}
                maxLength="500"
              ></Textarea>
            </SelfIntroWrap>
            <SelfIntroLength>{text.length}/500자</SelfIntroLength>
          </ThirdSection>
        </ResumeRegiWrap>
        <SubmitButton type="submit">이력서 {resumeId?'수정':'등록'}</SubmitButton>
      </Wrap>
      <Footer />
      <PopUp visible={popupVisible} onClose={handleClosePopup}>
        {countryPopupVisible && <><PopUpTitle>국가 선택</PopUpTitle><PopUpTab>
          {counrtyStore.countryData.map((item) => (
            <PopUpLanguageItemWrap
              onClick={() => handleCountryTab({ countryIsoCode: item.countryIsoCode, name: item.name })}
              tab={countryInfo?.countryIsoCode === item.countryIsoCode}
              key={item.countryIsoCode}
            >
              <PopUpLanguageItem>
                <Flag>
                  <img src={item.flagImgUrl} alt="flag" />
                </Flag>
                <FlagText>{item.name}</FlagText>
              </PopUpLanguageItem>
            </PopUpLanguageItemWrap>
          ))}
        </PopUpTab></>}
        {imagePopupVisible && <>
          <PopUpImage>
          <img src={popupPhoto} alt="popup" />
        </PopUpImage>
        </>}
        <PopUpButton onClick={handleClosePopup}>확인</PopUpButton>
      </PopUp>
    </>
  );
});

const Wrap = styled.form`
  margin: 80px auto 250px;
  width: 1100px;
`;

const Title = styled.p`
  font-size: 35px;
  letter-spacing: -1.75px;
  font-family: 'nexonBold';
  margin: 0 0 10px 10px; ;
`;

const ResumeRegiWrap = styled.div`
  border: 2px solid ${theme.colors.border};
  border-radius: 10px;
`;

const FirstSection = styled.div`
  padding: 30px 40px 60px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Photo = styled.div`
  width: 189px;
  height: 226px;
  background-color: #e6e4f2;
  border: 2px solid #d0d2e8;
  margin: 15px 0 0 0;
`;

const Img = styled.img`
  width: 185px;
  height: 222px;
  object-fit: cover;
  cursor: pointer;
`;

const ImgIcon = styled(BsFillPersonFill)`
  font-size: 180px;
  color: white;
  padding: 40px 4px;
  cursor: pointer;
`;

const ImgInput = styled.input`
  display: none;
`;

const PersonalInfo = styled.div`
  display: flex;
`;

const PhotoWrap = styled.div``;

const Label = styled.p`
  font-size: 20px;
  font-family: 'nexonMedium';
  color: ${theme.colors.bodyText};
  margin: 0 0 45px 0;
`;

const InfoBoxWrap = styled.div`
  margin: 38px 0 0 87px;
`;

const InfoBox = styled.div`
  font-size: 20px;
  display: flex;

  & + & {
    margin: 30px 0 0 0;
  }
`;

const InfoTitle = styled.p`
  font-family: 'nexonMedium';
  width: 130px;
  margin: 4px 0 0 0;
`;

const InfoInput = styled.input`
  border: 0;
  border-bottom: 2px solid #a2a5d2;
  font-size: 18px;
  font-family: 'nexonRegular';
  width: 390px;
  color: ${theme.colors.bodyText};

  &::placeholder {
    color: #d0d2e8;
  }
`;

const CountryPopupButton = styled.button`
  border: 0;
  border-bottom: 2px solid #a2a5d2;
  font-size: 18px;
  font-family: 'nexonRegular';
  width: 390px;
  background-color: transparent;
  text-align: left;
  padding: 0 0 0 2px;
`;

const CountryDisabled = styled.p`
  color: #d0d2e8;
`;

const CountryActive = styled.p`
  color: ${theme.colors.bodyText};
`;

const GenderSelectOptionsInput = styled.div`
  width: 390px;
`;

const DivideBox = styled.div`
  background-color: #eef1f5;
  height: 18px;
`;

const SecondSection = styled.div`
  padding: 30px 40px 60px 40px;
`;

const ThirdSection = styled.div`
  padding: 30px 40px 60px 40px;
`;

const CareerInputWrap = styled.div`
  margin: 15px 0 0 0;
  display: flex;
  justify-content: space-between;
`;

const DisabledCareerInputWrap = styled.div`
  margin: 0 0 50px 0;
  border-bottom: 2px solid #eee;
  padding: 0 0 50px 0;
`;

const DisabledCareerInputWrapFlex = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CareerInputLeft = styled.div``;

const CarrerInputBox = styled.div`
  display: flex;

  & + & {
    margin: 12px 0 0 0;
  }
`;

const InputLabel = styled.p`
  font-family: 'nexonMedium';
  color: #434343;
  font-size: 22px;
  width: 150px;
  margin: 20px 0 0 0;
`;

const CareerInput = styled.input`
  width: 383px;
  height: 72px;
  border: 2px solid #d0d2e8;
  color: #595959;
  font-size: 22px;
  padding: 0 0 0 28px;
`;
const CareerInputRight = styled.div``;

const SelfIntroWrap = styled.div`
  margin: 0 0 0 0;
`;

const Textarea = styled.textarea`
  width: 1020px;
  height: 195px;
  box-sizing: border-box;
  border: 2px solid #a2a5d2;
  font-family: 'nexonRegular';
  font-size: 18px;
  padding: 20px;
  resize: none;
  line-height: 30px;
  &:focus {
    outline: none;
  }
`;

const SelfIntroLength = styled.div`
  font-size: 16px;
  text-align: right;
  margin: 9px 0 0 0;
  font-family: 'nexonRegular';
`;

const SubmitButton = styled.button`
  width: 340px;
  height: 62px;
  background-color: ${theme.colors.primary};
  color: white;
  border: 0;
  font-size: 20px;
  display: block;
  margin: 80px auto 0;
`;

const CareerBox = styled.div`
  width: 460px;

  & + & {
    margin: 140px 0 0 0;
  }
`;

const CareerItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;

  & + & {
    margin: 20px 0 0 0;
  }
`;

const CareerTitle = styled.div`
  min-width: 70px;
  color: ${theme.colors.bodyText};
  font-size: 18px;
  font-family: nexonMedium;
  letter-spacing: -1.1px;
`;

const CareerDataBox = styled.input`
  color: #595959;
  height: 57px;
  width: 390px;
  border: 2px solid #d0d2e8;
  font-family: nexonRegular;
  font-size: 17px;
  padding: 0 0 0 23px;

  &::placeholder {
    color: #d0d2e8;
  }

  &:disabled {
    background-color: #eee;
    color: #888;
  }
`;

const DisabledCareerDataBox = styled.div`
  color: #888;
  height: 57px;
  width: 390px;
  border: 2px solid #d0d2e8;
  font-family: nexonRegular;
  font-size: 18px;
  padding: 18px 0 0 30px;
  background-color: #eee;
`;

const DisabledCareerDataBoxSmall = styled(DisabledCareerDataBox)`
  width: 200px;
`;

const LargeSelectedOptionWrap = styled.div`
  width: 390px;
`;

const InputDisabled = styled.div`
  width: 100%;
  cursor: not-allowed;
  border: 2px solid #d0d2e8;
  font-size: 18px;
  height: 57px;
  padding: 18px 0 0 30px;
  background-color: #eee;
  color: #888;
`;

const CareerDataImage = styled.div`
  font-family: nexonRegular;
  display: flex;
  align-items: center;
`;

const ImageSrc = styled.p`
  font-size: 18px;
  color: ${theme.colors.bodyText};
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
`;

const DisabledImgSrc = styled.a`
  font-size: 18px;
  color: #888;
  cursor: pointer;
`;

const ImageShowButton = styled.button`
  background-color: #d0d2e8;
  width: 120px;
  height: 42px;
  border-radius: 5px;
  font-size: 16px;
  color: #595959;
  margin: 0 0 0 20px;
`;

const CareerDataBoxSmall = styled(CareerDataBox)`
  width: 160px;
  &:disabled {
    background-color: #eee;
    color: #888;
  }
`;
const CareerTitleMiddle = styled(CareerTitle)`
  text-align: center;
  width: 60px;
`;

const PlusButton = styled.button`
  background-color: rgba(208, 210, 232, 0.5);
  width: 100%;
  height: 34px;
  margin: 40px 0 80px 0;

  & > p {
    font-family: 'nexonBold';
    color: #a1acbe;
    font-size: 40px;
    line-height: 20px;
    margin: 4px 0 0 0;
  }
`;

const KoreanSkillWrap = styled.div`
  width: 210px;
`;

const CareerHopeItem = styled.div`
  display: flex;
  justify-content: space-between;

  & + & {
    margin: 20px 0 0 0;
  }
`;

const CareerHopeBoxWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: -23px;
`;

const CareerHopeDataBox = styled(CareerDataBox)`
  margin: 0 50px 10px 0;
`;

const PlusButtonSmall = styled(PlusButton)`
  width: 390px;
  margin: 15px 0 0 0;
`;
const CareerHopeInputWrap = styled(CareerInputWrap)`
  margin: 100px 0 0 0;
`;

const MoneySymbol = styled.p`
  font-size: 25px;
  margin: 0 0 0 10px;
`;

const ButtonFlexWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const DeleteButton = styled.button`
  font-size: 18px;
  font-family: nexonRegular;
  width: 120px;
  height: 40px;
  border-radius: 5px;
  border: 2px solid #ddd;
  color: #777;
  margin: 20px 0 0 0;
`;

const PopUpTitle = styled.p`
  text-align: center;
  font-family: 'nexonRegular';
  font-size: 25px;
`;

const PopUpTab = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 70px 0 0 0;
`;

const PopUpLanguageItemWrap = styled.div`
  width: 92px;
  height: 108px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${(props) =>
    props.tab === true &&
    css`
      background-color: rgba(7, 0, 133, 0.2);
      border-radius: 5px;
    `}
`;

const PopUpLanguageItem = styled.div`
  text-align: center;
`;

const Flag = styled.div`
  width: 30px;
  height: 20px;
  margin: 0 auto 25px;

  & > img {
    width: 30px;
    object-fit: cover;
  }
`;

const PopUpImage = styled.div`
  width: 400px;
  margin: 0 auto;
  background-color: #eee;

  & > img {
    width: 400px;
  }
`;

const PopUpButton = styled.button`
  width: 128px;
  border-radius: 5px;
  height: 48px;
  border: 2px solid ${theme.colors.primary};
  margin: 20px auto;
  color: ${theme.colors.primary};
  background-color: white;
  font-size: 20px;
  display: block;
`;

const FlagText = styled.p`
  font-size: 15px;
  font-family: 'nexonRegular';
`;

export default ResumeRegistration;
