import React from 'react';
import styled from 'styled-components';

const Footer = () => {
  return (
    <Wrap>
      <Logo>KO-LIFE</Logo>
      <Info>
        코트립 KOTRIP<br />
        사업자 등록번호: 775-16-00541 | 대표: 김현우
        <br />
        충청북도 충주시 충원대로 268 건국대학교 창업보육센터 407호 <br/>
        전화: 043-851-0513 | 이메일: kotrip@naver.com
      </Info>
    </Wrap>
  );
};

const Wrap = styled.div`
  background-color: #d1d5da;
  height: 290px;
  padding: 42px 0 0 355px;
  width: 100vw;
  overflow: hidden;
  min-width: 1417px;
  display: block;
`;

const Logo = styled.div`
  color: white;
  font-size: 24px;
`;

const Info = styled.p`
  color: #6a697a;
  font-family: 'nexonLight';
  font-size: 20px;
  margin: 36px 0 0 0;
  line-height: 40px;
`;

export default Footer;
