import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import { Link, useNavigate } from 'react-router-dom';
import stores from 'stores';

const Navbar = () => {
  const navigate = useNavigate();
  const { authStore } = stores;
  const [login, setLogin] = useState(localStorage.getItem('token'));

  const logout = () => {
    if(window.confirm('로그아웃 하시겠습니까?')) {
      authStore.postUserLogout()   
    }
  }
  
  return (
    <Wrap>
      <FlexWrap>
        <Link to="/">
          <Logo><LogoImg src="/images/logo_global.png"/></Logo>
        </Link>
        <RightSide>
          {login ? (
            <MenuList>
              <MenuItem onClick={() => {
                if (localStorage.getItem('role') !== 'EMPLOYER') {
                  alert('기업으로 로그인 후 조회가능한 페이지 입니다.')
                  navigate('/login')
                } else {
                  navigate(`/findWorkers`)
                }
              }}>해외인재
              </MenuItem>
              <MenuItem onClick={() => {
                if (localStorage.getItem('role') !== 'EMPLOYEE') {
                  alert('구직자로 로그인 후 조회가능한 페이지 입니다.')
                  navigate('/login')
                } else {
                  navigate(`/resumeRegistration`)
                }
              }}>이력서 작성
              </MenuItem>
              <MenuItem onClick={logout}>로그아웃</MenuItem>
            </MenuList>
          ) : (
            <MenuList>
              <MenuItem>
                <Link to="/login">로그인</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/signUp/coworker">회원가입</Link>
              </MenuItem>
            </MenuList>
          )}

          <HamburgerIcon>
            <Bar></Bar>
            <Bar></Bar>
            <Bar></Bar>
          </HamburgerIcon>
        </RightSide>
      </FlexWrap>
    </Wrap>
  );
};

const LogoImg = styled.img`
  height: 50px;
`;

const Wrap = styled.div`
  height: 120px;
  box-shadow: 0 0 18.9px 2.1px rgba(38, 42, 47, 0.5);
  display: flex;
  justify-content: center;
  width: 100%;
`;

const FlexWrap = styled.div`
  width: 1100px;
  min-width: 600px;
  display: flex;
  justify-content: space-between;
`;

const Logo = styled.div`
  margin: 53px 0 0 0;
  font-size: 40px;
  font-family: 'nexonBold';
`;

const RightSide = styled.div`
  margin: 65px 0 0 0;
  display: flex;
`;

const MenuList = styled.ul`
  display: flex;
  margin: 0;
  margin-top: -20px;
`;

const MenuItem = styled.button`
  font-family: 'nexonMedium';
  font-size: 22px;
  letter-spacing: -1.25px;
  background: transparent;
  & + & {
    margin: 0 0 0 40px;
  }

  & > a {
    color: ${theme.colors.bodyText};
  }
`;

const HamburgerIcon = styled.div`
  margin: 0 0 0 60px;
  cursor: pointer;
`;

const Bar = styled.div`
  width: 37px;
  height: 3px;
  border-radius: 2px;
  background-color: ${theme.colors.bodyText};

  & + & {
    margin: 9px 0 0 0;
  }
`;

export default Navbar;
