import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import Navbar from 'components/templates/Navbar';
import Footer from 'components/templates/Footer';
import PopUp from 'components/atoms/PopUp';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import stores from 'stores';
import { useParams, useNavigate } from 'react-router-dom';

const ResumeView = observer(() => {
  const { overseasStore } = stores;
  const navigate = useNavigate();

  let { resumeId } = useParams();

  const [resumeData, setResumeData] = useState({});

  useEffect(() => {
    overseasStore.getResumeById(resumeId);
  }, [overseasStore, resumeId]);

  useEffect(() => {
    if (!overseasStore.resumeByIdData) {
      return;
    }
    if (localStorage.getItem('role') !== 'EMPLOYER' && localStorage.getItem('id') != overseasStore.resumeByIdData.user?.id) {
      navigate('/login')
      return;
    }
    setResumeData(overseasStore.resumeByIdData);
  }, [overseasStore.resumeByIdData]);

  function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const [popupVisible, setPopupVisible] = useState(false);
  const [popupPhoto, setPopupPhoto] = useState('');

  const handleClickPopup = () => {
    setPopupVisible(!popupVisible);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  const handleShowPopup = (url) => {
    console.log('url', url);
    if (url.endsWith('.png') || url.endsWith('.jpg') || url.endsWith('.jpeg')) {
      setPopupVisible(true);
      setPopupPhoto(url);
    } else {
      window.open(url);
    }
  };

  const translateContents = () => {
    let langCode = localStorage.getItem('languageCode');
    overseasStore.getResumeById(resumeId, langCode?langCode:'');
  }

  return (
    <>
      <Navbar />
      <Wrap>
        <Title>이력서 보기</Title>
        <ResumeWrap>
          <FirstSection>
            <PersonalInfo>
              <Photo>
                {resumeData?.profileImageUrl ? (
                  <img src={resumeData?.profileImageUrl} alt="profile-img" />
                ) : (
                  ''
                )}
              </Photo>
              <InfoBoxWrap>
                <InfoBox>
                  <InfoTitle>이름</InfoTitle>
                  <InfoData>{resumeData?.name}</InfoData>
                </InfoBox>
                <InfoBox>
                  <InfoTitle>국적</InfoTitle>
                  <InfoData>{resumeData?.country?.name}</InfoData>
                </InfoBox>
                <InfoBox>
                  <InfoTitle>성별</InfoTitle>
                  <InfoData>
                    {resumeData?.gender === 'MAN'
                      ? '남성'
                      : resumeData?.gender === 'WOMAN'
                      ? '여성'
                      : '알 수 없음'}
                  </InfoData>
                </InfoBox>
                <InfoBox>
                  <InfoTitle>생년월일</InfoTitle>
                  <InfoData>{resumeData?.birthDate}</InfoData>
                </InfoBox>
                <InfoBox>
                  <InfoTitle>연락처</InfoTitle>
                  <InfoData>{resumeData?.cellphone}</InfoData>
                </InfoBox>
                <InfoBox>
                  <InfoTitle>이메일</InfoTitle>
                  <InfoData>{resumeData?.email}</InfoData>
                </InfoBox>
              </InfoBoxWrap>
            </PersonalInfo>
            <ProposalButton onClick={() => {
              if (localStorage.getItem('role') !== 'EMPLOYER') {
                alert('기업으로 로그인 후 조회가능한 페이지 입니다.')
                navigate(`/login`)
              } else {
                navigate(`/recruitment/${resumeId}`)
              }
            }}>채용제안 보내기</ProposalButton>
          </FirstSection>
          <DivideBox></DivideBox>
          <SecondSection>
            <SecondSectionTop>
              <TranslationButton onClick={translateContents}>번역</TranslationButton>
              <Label>이력사항</Label>
              <CareerWrap>
                <CareerLeftWrap>
                  {resumeData?.academicList?.map((item) => (
                    <CareerBox>
                      <CareerItem>
                        <CareerTitle>학력</CareerTitle>
                        <CareerDataBox>
                          {item.academicBackgroundType === 'HIGH_SCHOOL_GRADUATE'
                            ? '고등학교 졸업'
                            : item.academicBackgroundType === 'COLLEGE_GRADUATE'
                            ? '대학 졸업'
                            : item.academicBackgroundType === 'KOREA_COLLEGE_GRADUATE'
                            ? '국내대학 졸업'
                            : item.academicBackgroundType === 'TOP_50_COLLEGE_GRADUATE'
                            ? 'TOP 50 대학 졸업'
                            : item.academicBackgroundType === 'MASTER'
                            ? '석사'
                            : item.academicBackgroundType === 'DOCTOR'
                            ? '박사'
                            : '-'}
                        </CareerDataBox>
                      </CareerItem>
                      <CareerItem>
                        {item.academicCertificateUrl && <>
                        <CareerTitle>학력 증명서</CareerTitle>
                        <CareerDataImage>
                          <ImageSrc>학력 증명서 파일</ImageSrc>
                          <ImageShowButton
                            onClick={() => handleShowPopup(item.academicCertificateUrl)}
                          >
                            자세히보기
                          </ImageShowButton>
                        </CareerDataImage>
                        </>}
                        {
                          !item.academicCertificateUrl && 
                          <>
                            <CareerTitle>학력 증명서</CareerTitle>
                            <CareerDataImage>
                              <ImageSrc>파일 없음</ImageSrc>
                            </CareerDataImage>
                          </>
                        }
                      </CareerItem>
                    </CareerBox>
                  ))}
                  <hr />
                  {resumeData?.careerList?.map((item) => (
                    <CareerBox>
                      <CareerItem>
                        <CareerTitle>경력</CareerTitle>
                        <CareerDataBox>
                          경력{' '}
                          {item.workExperienceType === 'ONE'
                            ? '1년'
                            : item.workExperienceType === 'TWO'
                            ? '2년'
                            : item.workExperienceType === 'THREE'
                            ? '3년'
                            : item.workExperienceType === 'FIVE'
                            ? '5년'
                            : item.workExperienceType === 'EIGHT'
                            ? '8년'
                            : item.workExperienceType === 'TEN'
                            ? '10년'
                            : item.workExperienceType === 'TEN ABOVE'
                            ? '10년 이상'
                            : '-'}
                        </CareerDataBox>
                      </CareerItem>
                      <CareerItem>
                        {item.careerCertificateUrl && <>
                        <CareerTitle>경력 증명서</CareerTitle>
                        <CareerDataImage>
                          <ImageSrc>경력 증명서 파일</ImageSrc>
                          <ImageShowButton
                            onClick={() => handleShowPopup(item.careerCertificateUrl)}
                          >
                            자세히보기
                          </ImageShowButton>
                        </CareerDataImage>
                        </>}
                        {
                          !item.careerCertificateUrl && 
                          <>
                            <CareerTitle>경력 증명서</CareerTitle>
                            <CareerDataImage>
                              <ImageSrc>파일 없음</ImageSrc>
                            </CareerDataImage>
                          </>
                        }
                      </CareerItem>
                    </CareerBox>
                  ))}
                  <hr />
                  <CareerBox>
                    <CareerItem>
                      <CareerTitle>
                        한국어
                        <br />
                        능력
                      </CareerTitle>
                      {/* <KoreanSkillWrap>
                        <SelectOptions
                          value={koreanSkill}
                          onSelect={(val) => setKoreanSkill(val)}
                          options={koreanSkillOpion}
                          style={{ height: '70px' }}
                          border={true}
                        />
                      </KoreanSkillWrap> */}
                      <CareerDataBoxSmall>
                        {resumeData?.koreanCapability === 'VERY_GOOD'
                          ? '최상'
                          : resumeData?.koreanCapability === 'GOOD'
                          ? '상'
                          : resumeData?.koreanCapability === 'NORMAL'
                          ? '중'
                          : resumeData?.koreanCapability === 'BAD'
                          ? '하'
                          : resumeData?.koreanCapability === 'VERY_BAD'
                          ? '최하'
                          : '-'}
                      </CareerDataBoxSmall>
                      <CareerTitleMiddle>급수</CareerTitleMiddle>
                      <CareerDataBoxSmall>{resumeData?.koreanCapabilityGrade}</CareerDataBoxSmall>
                    </CareerItem>
                  </CareerBox>

                  <hr />
                  <CareerBox>
                    <ExpectedJobCareerItem>
                      <ExpectedJobCareerTitle>희망업무</ExpectedJobCareerTitle>
                      <ExpectedJobCareerBox>
                        {resumeData?.expectedJobs?.map((item) => (
                          <CareerDataBox>{item}</CareerDataBox>
                        ))}
                      </ExpectedJobCareerBox>
                    </ExpectedJobCareerItem>
                  </CareerBox>
                  <CareerBox>
                    <CareerItem>
                      <CareerTitle>희망연봉</CareerTitle>
                      <CareerDataBox>
                        {numberWithCommas(resumeData?.expectedPayAmount)}
                      </CareerDataBox>
                      <MoneySymbol>₩</MoneySymbol>
                    </CareerItem>
                  </CareerBox>
                </CareerLeftWrap>
                <CareerRightWrap>
                  {resumeData?.academicList?.map((item) => (
                    <CareerBox>
                      <CareerItem>
                        <CareerTitle>학교명</CareerTitle>
                        <CareerDataBox>{item.academicName}</CareerDataBox>
                      </CareerItem>
                      <CareerItem>
                        <CareerTitle>소재지</CareerTitle>
                        <CareerDataBoxSmall>{item.academicCountry}</CareerDataBoxSmall>
                        <CareerTitleMiddle>학과</CareerTitleMiddle>
                        <CareerDataBoxSmall>{item.academicMajor}</CareerDataBoxSmall>
                      </CareerItem>
                    </CareerBox>
                  ))}
                  <hr />
                  {resumeData?.careerList?.map((item) => (
                    <CareerBox>
                      <CareerItem>
                        <CareerTitle>회사명</CareerTitle>
                        <CareerDataBoxSmall>{item.companyName}</CareerDataBoxSmall>
                        <CareerTitleMiddle>직급</CareerTitleMiddle>
                        <CareerDataBoxSmall>{item.position}</CareerDataBoxSmall>
                      </CareerItem>
                      <CareerItem>
                        <CareerTitle>상세업무</CareerTitle>
                        <CareerDataBox>{item.careerDetails}</CareerDataBox>
                      </CareerItem>
                    </CareerBox>
                  ))}
                  <hr />
                  <CareerBox>
                    <CareerItem>
                      {resumeData?.koreanCapabilityCertificateUrl && <>
                      <CareerTitle>한국어 능력 시험</CareerTitle>
                      <CareerDataImage>
                        <ImageSrc>증명서 파일</ImageSrc>
                        <ImageShowButton
                          onClick={() =>
                            handleShowPopup(resumeData?.koreanCapabilityCertificateUrl)
                          }
                        >
                          자세히보기
                        </ImageShowButton>
                      </CareerDataImage>
                      </>}
                      {
                          !resumeData.koreanCapabilityCertificateUrl && 
                          <>
                            <CareerTitle>증명서</CareerTitle>
                            <CareerDataImage>
                              <ImageSrc>파일 없음</ImageSrc>
                            </CareerDataImage>
                          </>
                        }
                    </CareerItem>
                  </CareerBox>
                </CareerRightWrap>
              </CareerWrap>
            </SecondSectionTop>
            <SecondSectionBottom>
              <Label>자기소개</Label>
              <SelfIntroWrap>
                <Translation>{resumeData?.introduction}</Translation>
              </SelfIntroWrap>
              <SelfIntroLength>{resumeData?.introduction?.length}/500자</SelfIntroLength>
            </SecondSectionBottom>
          </SecondSection>
        </ResumeWrap>
        <ErrorText>
          ※구인/구직 목적 이외에 다른 목적으로 이용시 이력서 삭제 혹은 비공개 조치가 될 수 있습니다.
        </ErrorText>
      </Wrap>
      <Footer />

      <PopUp visible={popupVisible} onClose={handleClosePopup}>
        <PopUpImage>
          <img src={popupPhoto} alt="popup" />
        </PopUpImage>
        <PopUpButton onClick={handleClickPopup}>확인</PopUpButton>
      </PopUp>
    </>
  );
});

const Wrap = styled.div`
  margin: 80px auto 250px;
  width: 1100px;
`;

const Title = styled.p`
  font-size: 35px;
  letter-spacing: -1.75px;
  font-family: 'nexonBold';
  margin: 0 0 10px 10px; ;
`;

const ResumeWrap = styled.div`
  border: 2px solid ${theme.colors.border};
  border-radius: 10px;
`;

const FirstSection = styled.div`
  padding: 50px 65px 60px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Photo = styled.div`
  width: 183px;
  height: 242px;
  background-color: #e6e4f2;
  border: 2px solid #d0d2e8;

  & > img {
    object-fit: cover;
    width: 179px;
    height: 238px;
  }
`;

const PersonalInfo = styled.div`
  display: flex;
`;

const InfoBoxWrap = styled.div`
  margin: 0 0 0 45px;
`;

const InfoBox = styled.div`
  font-size: 20px;
  display: flex;

  & + & {
    margin: 24px 0 0 0;
  }
`;

const InfoTitle = styled.p`
  font-family: 'nexonMedium';
  width: 130px;
`;
const InfoData = styled.p`
  font-family: 'nexonRegular';
`;

const ProposalButton = styled.button`
  width: 230px;
  height: 79px;
  border-radius: 10px;
  border: 4px solid ${theme.colors.primary};
  background-color: white;
  font-size: 24px;
  font-family: 'nexonMedium';
  color: ${theme.colors.bodyText};
`;

const DivideBox = styled.div`
  background-color: #eef1f5;
  height: 15px;
`;

const SecondSection = styled.div`
  padding: 47px 47px 60px 47px;
  font-family: 'nexonRegular';
`;

const SecondSectionTop = styled.div`
  position: relative;
`;
const Label = styled.p`
  font-size: 20px;
  font-family: 'nexonMedium';
  color: ${theme.colors.bodyText};
  margin: 0 0 34px 0;
`;

const CareerWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CareerLeftWrap = styled.div``;

const CareerRightWrap = styled.div``;

const CareerBox = styled.div`
  width: 460px;

  & + & {
    margin: 40px 0 0 0;
  }
`;

const CareerItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 70px;

  & + & {
    margin: 16px 0 0 0;
  }
`;

const CareerTitle = styled.div`
  min-width: 90px;
  color: ${theme.colors.bodyText};
  font-size: 17px;
  font-family: 'nexonMedium';
  letter-spacing: -1.1px;
`;

const CareerDataBox = styled.div`
  color: #595959;
  height: 56px;
  width: 384px;
  border: 2px solid #d0d2e8;
  font-family: 'nexonRegular';
  font-size: 17px;
  padding: 18px 0 0 24px;

  & + & {
    margin: 10px 0 0 0;
  }
`;

const CareerDataImage = styled.div`
  font-family: 'nexonRegular';
  display: flex;
  align-items: center;
`;

const ImageSrc = styled.p`
  font-size: 17px;
  color: ${theme.colors.bodyText};
`;

const ImageShowButton = styled.button`
  background-color: #d0d2e8;
  width: 117px;
  height: 41px;
  border-radius: 5px;
  font-size: 16px;
  color: #595959;
  margin: 0 0 0 20px;
  font-family: 'nexonRegular';
`;

const SecondSectionBottom = styled.div`
  margin: 70px 0 0 0;
`;

const SelfIntroWrap = styled.div`
  border: solid 2px #d0d2e8;
  margin: 15px 0 0 0;
  height: 240px;
`;

const Native = styled.p`
  padding: 47px 0;
  margin: 0 30px;
  font-size: 22px;
  color: #595959;
  border-bottom: 2px solid #d0d2e8;
`;

const Translation = styled.p`
  padding: 23px 0 0 23px;
  font-size: 17px;
  color: #595959;
`;

const SelfIntroLength = styled.div`
  font-size: 16px;
  text-align: right;
  margin: 9px 0 0 0;
`;

const ErrorText = styled.p`
  font-size: 16px;
  color: #b85565;
  margin: 17px 0 0 20px;
  font-family: 'nexonRegular';
  letter-spacing: -1px;
`;

const TranslationButton = styled.button`
  background: transparent;
  border: 3px solid ${theme.colors.primary};
  border-radius: 30px;
  color: ${theme.colors.primary};
  font-size: 18px;
  font-family: nexonMedium;
  width: 88px;
  height: 35px;
  position: absolute;
  top: 0;
  right: 0;
`;

const KoreanSkillWrap = styled.div`
  width: 210px;
`;

const CareerDataBoxSmall = styled(CareerDataBox)`
  width: 151px;
`;

const CareerTitleMiddle = styled(CareerTitle)`
  text-align: center;
`;

const MoneySymbol = styled.p`
  font-size: 25px;
  margin: 0 0 0 10px;
`;

const PopUpImage = styled.div`
  width: 780px;
  background-color: #eee;

  & > img {
    width: 780px;
  }
`;

const PopUpButton = styled.button`
  width: 128px;
  border-radius: 5px;
  height: 48px;
  border: 2px solid ${theme.colors.primary};
  margin: 20px auto;
  color: ${theme.colors.primary};
  background-color: white;
  font-size: 20px;
  display: block;
`;

const ExpectedJobCareerBox = styled.div`
  width: 490px;
`;

const ExpectedJobCareerItem = styled(CareerItem)`
  align-items: flex-start;
`;

const ExpectedJobCareerTitle = styled(CareerTitle)`
  margin: 22px 0 0 0;
`;

export default ResumeView;
