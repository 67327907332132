import overseasStore from './OverseasStore';
import fileStore from './FileStore';
import counrtyStore from './CountryStore';
import authStore from './AuthStore';

const stores = {
  overseasStore,
  fileStore,
  counrtyStore,
  authStore,
};

export default stores;
