import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';

const SkillBox = ({ skills }) => {
  return (
    <>
      {skills === 'VERY_GOOD' ? (
        <Wrap>
          <SkillItem></SkillItem>
          <SkillItem></SkillItem>
          <SkillItem></SkillItem>
          <SkillItem></SkillItem>
          <SkillItem></SkillItem>
        </Wrap>
      ) : skills === 'GOOD' ? (
        <Wrap>
          <SkillItem></SkillItem>
          <SkillItem></SkillItem>
          <SkillItem></SkillItem>
          <SkillItem></SkillItem>
        </Wrap>
      ) : skills === 'NORMAL' ? (
        <Wrap>
          <SkillItem></SkillItem>
          <SkillItem></SkillItem>
          <SkillItem></SkillItem>
        </Wrap>
      ) : skills === 'BAD' ? (
        <Wrap>
          <SkillItem></SkillItem>
          <SkillItem></SkillItem>
        </Wrap>
      ) : skills === 'VERY_BAD' ? (
        <Wrap>
          <SkillItem></SkillItem>
        </Wrap>
      ) : (
        <></>
      )}
    </>
  );
};

const Wrap = styled.div`
  width: 74px;
  height: 16px;
  border-radius: 8px;
  border: 2px solid #434343;
  display: flex;
  padding: 2px 3px;
`;

const SkillItem = styled.div`
  width: 12px;
  height: 8px;
  background-color: ${theme.colors.primary};

  & + & {
    margin-left: 1px;
  }

  :last-child {
    border-radius: 0 8px 8px 0;
  }

  :first-child {
    border-radius: 8px 0 0 8px;
  }
`;

export default SkillBox;
