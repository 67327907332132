import React, { useRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import useDetectOutsideClick from 'utils/useDetectOutsideClick';
import { MdKeyboardArrowDown } from 'react-icons/md';

const SelectOptions = ({ options, value, onSelect, style, line, border, expand }) => {
  const wrapperRef = useRef(null);
  const [visible, setVisible] = useDetectOutsideClick(wrapperRef, false);
  const [optionsScroll, setOptionScroll] = useState(false);

  useEffect(() => {
    //optionScroll
    if (options.length > 10) {
      setOptionScroll(true);
    }
  }, [options.length]);

  return (
    <>
      <DropdownWrapper
        ref={wrapperRef}
        onClick={() => setVisible(!visible)}
        style={style}
        align="center"
      >
        <SelectedDropdownWrap
          focus={visible || undefined}
          style={style}
          line={line}
          border={border}
        >
          <SelectedLabel border={border}>{value.text}</SelectedLabel>
          <SelectIcon active={visible} border={border} />
        </SelectedDropdownWrap>
        {visible && (
          <DropdownList scroll={optionsScroll} border={border} expand={expand}>
            {options
              ? options.map((item, index) => (
                  <DropdownListItem
                    key={`${item}${index}`}
                    onClick={() => {
                      onSelect(item);
                      setVisible(false);
                    }}
                    border={index !== options.length - 1}
                  >
                    <DropdownListItemText>{item.text}</DropdownListItemText>
                  </DropdownListItem>
                ))
              : null}
          </DropdownList>
        )}
      </DropdownWrapper>
    </>
  );
};

const DropdownWrapper = styled.div`
  position: relative;
  display: table-cell;
  text-align: left;
  width: 100vw;
  vertical-align: middle;
  justify-content: center;
  cursor: pointer;
  ${(props) => (props.style ? props.style : null)}
`;

const SelectedDropdownWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  position: relative;
  background: #fff;
  z-index: 1;

  ${(props) =>
    props.line === true &&
    css`
      border-bottom: 2px solid #a2a5d2;
    `}

  ${(props) =>
    props.border === true &&
    css`
      border: 2px solid #d0d2e8;
    `}
`;

const SelectedLabel = styled.p`
  margin: 0 5px 0 5px;
  overflow: hidden;
  font-weight: normal;
  font-size: 18px;
  color: #434343;

  ${(props) =>
    props.border === true &&
    css`
      padding: 0 0 0 30px;
    `}
`;
const DropdownList = styled.ul`
  position: absolute;
  width: calc(100% - 2px);
  top: 40px;
  min-height: 47px;
  margin: 0;
  padding: 0;
  background: #eef1f5;
  cursor: pointer;
  list-style: none;
  z-index: 2;
  ${(props) =>
    props.expand === true &&
    css`
      left: -90px;
      width: calc(200% + 10px);
    `}
  ${(props) =>
    props.scroll === true &&
    css`
      height: 200px;
      overflow-y: scroll;
    `}

  ${(props) =>
    props.border === true &&
    css`
      top: 70px;
    `}
`;

const DropdownListItem = styled.li`
  display: flex;
  align-items: center;
  padding-left: 17px;
  height: 47px;
  padding-right: 17px;
  border-bottom: ${(props) => (props.border ? '1px solid #ddd' : 'none')};
  :hover {
    background: rgba(172, 170, 192, 0.3);
  }
`;

const DropdownListItemText = styled.p`
  margin: 0;
  font-weight: normal;
  font-size: 20px;
  color: #3b393a;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const SelectIcon = styled(MdKeyboardArrowDown)`
  font-size: 30px;
  position: absolute;
  right: 0;
  margin: -5px 0 0 0;
  color: #d0d2e8;
  z-index: 1;

  ${(props) =>
    props.active === true &&
    css`
      color: ${theme.colors.primary};
    `}

  ${(props) =>
    props.border === true &&
    css`
      top: 20px;
      right: 10px;
    `}
`;

//   const DropdownIcon = styled(Dropdown)`
//   ${(props) => props.focus === 'true' && 'transform: rotate(180deg);'}
// `;

export default SelectOptions;
