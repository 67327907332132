import axios from 'axios';

export const instance = axios.create({
  baseURL: 'https://api.ko-life.co.kr',
});

instance.interceptors.request.use(
  function (config) {
    config.headers['Content-Type'] = 'application/json; charset=utf-8';
    if (localStorage.getItem('token')) {
      config.headers['X-KOLIFE-OVR-AUTH-TOKEN'] = localStorage.getItem('token');
    }
    return config;
  },
  function (error) {
    console.log(error);
    return Promise.reject(error);
  },
);

// instance.interceptors.response.use(
//   function (response) {
//     console.log(response);

//     return response;
//   },
//   function (error) {
//     console.log(error);
//   },
// );

export const postJobOffersApi = (body) => instance.post(`/overseas/job-offers`, body);

export const getResumeApi = (
  academicBackgroundType,
  countryIsoCode,
  keyword,
  koreanCapability,
  koreanCapabilityCertificate,
  pageNumber,
  pageSize,
  workExperienceType,
) =>
  instance.get(
    `/overseas/resumes?academicBackgroundType=${academicBackgroundType}&countryIsoCode=${countryIsoCode}&keyword=${keyword}&koreanCapability=${koreanCapability}&koreanCapabilityCertificate=${koreanCapabilityCertificate}&pageNumber=${pageNumber}&pageSize=${pageSize}&workExperienceType=${workExperienceType}`,
  );

export const postResumeApi = (body) => instance.post(`/overseas/resumes`, body);

export const updateFileApi = (data) => instance.post(`/files`, data);

export const getCountriesApi = () => instance.get(`/countries`);

export const getMyResumeApi = () => instance.get(`/overseas/users/resume`);

export const getResumeByIdApi = (resumeId, targetLangCode) => instance.get(`/overseas/resumes/${resumeId}?targetLangCode=${targetLangCode}`);

export const postUsersApi = (body) => instance.post(`/overseas/users`, body);

export const postUsersLoginApi = (body) => instance.post(`/overseas/users/authentication`, body);

export const postFindPasswordApi = (body) =>
  instance.post(`/overseas/email-for-find-password`, body);

export const postResetPasswordApi = (body) =>
  instance.post(`/overseas/reset-password`, body);

export const postFileApi = (body) => instance.post(`/files`, body);
