import { postJobOffersApi, getResumeApi, postResumeApi, 
  getMyResumeApi, getResumeByIdApi, postFileApi } from 'api/API';
import { makeAutoObservable, runInAction } from 'mobx';

class OverseasStore {
  resumesData = [];
  totalResumes = 0;
  resumeByIdData = {};

  constructor() {
    makeAutoObservable(this);
  }

  setResumesData = (param) => {
    this.resumesData = param;
  };

  setTotalResumes = (param) => {
    this.totalResumes = param;
  };

  setResumeByIdData = (param) => {
    this.resumeByIdData = param;
  };

  getMyResume = () => getMyResumeApi();

  postJobOffers = async (body) => {
    await postJobOffersApi(body)
      .then((res) => {
        console.log(res);
        if (res.status !== 200) {
          alert('요청에 실패했습니다.');
        } else {
          alert('성공적으로 등록되었습니다.');
          window.location.href = '/';
        }
      })
      .catch((error) => {
        console.log(error);
        alert('요청에 실패했습니다.')
      });
  };

  postResume = async (body) => {
    await postResumeApi(body)
      .then((res) => {
        console.log(res);
        if (res.status !== 200) {
          alert('요청에 실패했습니다.');
        } else {
          alert('성공적으로 등록되었습니다.');
          window.location.href = '/resumeRegistration';
        }
      })
      .catch((error) => {
        alert('요청에 실패했습니다.');
        console.log(error);
      });
  };

  getResume = async (
    academicBackgroundType,
    countryIsoCode,
    keyword,
    koreanCapability,
    koreanCapabilityCertificate,
    pageNumber,
    pageSize,
    workExperienceType,
  ) => {
    await getResumeApi(
      academicBackgroundType,
      countryIsoCode,
      keyword,
      koreanCapability,
      koreanCapabilityCertificate,
      pageNumber,
      pageSize,
      workExperienceType,
    )
      .then((res) => {
        this.setResumesData(res.data.data);
        this.setTotalResumes(res.data.totalCount);
        console.log(res, 'getResume');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getResumeById = async (resumeId, targetLangCode) => {
    await getResumeByIdApi(resumeId, targetLangCode)
      .then((res) => {
        console.log(res.data);
        this.setResumeByIdData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  postFile = (file) => postFileApi(file);
}

const overseasStore = new OverseasStore();

export default overseasStore;
