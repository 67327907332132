import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import Navbar from 'components/templates/Navbar';
import Footer from 'components/templates/Footer';
import FindWorkersTable from 'components/atoms/FindWorkersTable';
import Pagination from 'components/atoms/Pagination';
import { useNavigate } from "react-router-dom";

import { observer } from 'mobx-react';
import stores from 'stores';

import { FaMapMarkerAlt, FaHashtag } from 'react-icons/fa';

const FindWorkers = observer(() => {
  const { overseasStore, counrtyStore } = stores;

  const [page, setPage] = useState(1);
  const listPerPage = 12;
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('role') !== 'EMPLOYER') {
      navigate('/login')
    }
  }, [])

  useEffect(() => {
    overseasStore.getResume('', '', '', '', '', page - 1, listPerPage, '');
  }, [overseasStore, page, listPerPage]);

  useEffect(() => {
    counrtyStore.getCountries();
  }, [counrtyStore]);

  const educationData = [
    { value: 'HIGH_SCHOOL_GRADUATE', text: '고등학교 졸업' },

    { value: 'COLLEGE_GRADUATE', text: '대학 졸업' },
    { value: 'KOREA_COLLEGE_GRADUATE', text: '국내 대학 졸업' },
    { value: 'TOP_50_COLLEGE_GRADUATE', text: '세계 500대 대학 졸업' },
    { value: 'MASTER', text: '석사' },
    { value: 'DOCTOR', text: '박사' },
  ];

  const careerData = [
    { value: 'ONE', text: '1년' },
    { value: 'TWO', text: '2년' },
    { value: 'THREE', text: '3년' },
    { value: 'FIVE', text: '5년' },
    { value: 'EIGHT', text: '8년' },
    { value: 'TEN', text: '10년' },
    { value: 'TEN_ABOVE', text: '10년 이상' },
  ];

  const koreanSkillData = [
    { value: 'VERY_BAD', text: '최하' },
    { value: 'BAD', text: '하' },
    { value: 'NORMAL', text: '중' },
    { value: 'GOOD', text: '상' },
    { value: 'VERY_GOOD', text: '최상' },
  ];

  const [nationalCheckList, setNationalCheckList] = useState('');

  const handleClickNationalList = (item) => {
    setNationalCheckList(item);
  };

  const [educationCheckList, setEducationCheckList] = useState('');

  const handleClickEducationList = (item) => {
    setEducationCheckList(item);
  };

  const [careerCheckList, setCareerCheckList] = useState('');

  const handleClickCareerList = (item) => {
    setCareerCheckList(item);
  };

  const [koreanSkillCheckList, setKoreanSkillCheckList] = useState('');

  const handleClickKoreanSkillList = (item) => {
    setKoreanSkillCheckList(item);
  };

  const [koreanCapabilityCertificate, setKoreanCapabilityCertificate] = useState(false);

  const handleClickKoreanCapabilityCertificate = () => {
    setKoreanCapabilityCertificate(!koreanCapabilityCertificate);
  };

  const filteredAll = () => {
    overseasStore.getResume(
      educationCheckList,
      nationalCheckList,
      '',
      koreanSkillCheckList,
      koreanCapabilityCertificate,
      page - 1,
      listPerPage,
      careerCheckList,
    );
  };

  return (
    <>
      <Navbar />
      <Wrap>
        <Title>해외인재 상세 검색</Title>
        <FilterWrap>
          <FilterBox>
            <FilterTitle>
              <Icon>
                <FaMapMarkerAlt />
              </Icon>
              국적 검색
            </FilterTitle>
            <FilterItems>
              {counrtyStore.countryData.map((item) => (
                <FilterItem
                  onClick={() => handleClickNationalList(item.countryIsoCode)}
                  active={nationalCheckList === item.countryIsoCode}
                  key={item.countryIsoCode}
                >
                  {item.name}
                </FilterItem>
              ))}
            </FilterItems>
          </FilterBox>
          <FilterBox>
            <FilterTitle>
              <Icon>
                <FaHashtag />
              </Icon>
              학력 검색
            </FilterTitle>
            <FilterItems>
              {educationData.map((item) => (
                <FilterItem
                  onClick={() => handleClickEducationList(item.value)}
                  active={educationCheckList === item.value}
                  key={item.value}
                >
                  {item.text}
                </FilterItem>
              ))}
            </FilterItems>
          </FilterBox>
          <FilterBox>
            <FilterTitle>
              <Icon>
                <FaHashtag />
              </Icon>
              경력 검색
            </FilterTitle>
            <FilterItems>
              {careerData.map((item) => (
                <FilterItem
                  onClick={() => handleClickCareerList(item.value)}
                  active={careerCheckList === item.value}
                  key={item.value}
                >
                  {item.text}
                </FilterItem>
              ))}
            </FilterItems>
          </FilterBox>
          <FilterBox>
            <FilterTitle>
              <Icon>
                <FaHashtag />
              </Icon>
              한국어 검색
            </FilterTitle>
            <FilterItems>
              {koreanSkillData.map((item) => (
                <FilterItem
                  onClick={() => handleClickKoreanSkillList(item.value)}
                  active={koreanSkillCheckList === item.value}
                  key={item.value}
                >
                  {item.text}
                </FilterItem>
              ))}
              <FilterItem
                onClick={() => handleClickKoreanCapabilityCertificate()}
                active={koreanCapabilityCertificate}
              >
                한국어 자격증 보유
              </FilterItem>
            </FilterItems>
          </FilterBox>
          {/* <FilterBox>
            <FilterTitle>
              <Icon>
                <FaHashtag />
              </Icon>
              희망업무
              <br />
              검색
            </FilterTitle>
            <FilterItems>
              {hopeWorkData.map((item) => (
                <FilterItem
                  onClick={() => handleClickHopeWorkList(item)}
                  active={hopeWorkCheckList.includes(item)}
                  key={item}
                >
                  {item}
                </FilterItem>
              ))}
            </FilterItems>
          </FilterBox>
          <FilterBox>
            <FilterTitle>
              <Icon>
                <FaHashtag />
              </Icon>
              희망연봉
              <br />
              검색
            </FilterTitle>
            <FilterItems>
              {hopeSalaryData.map((item) => (
                <FilterItem
                  onClick={() => handleClickHopeSalaryList(item)}
                  active={hopeSalaryCheckList.includes(item)}
                  key={item}
                >
                  {item}
                </FilterItem>
              ))}
            </FilterItems>
          </FilterBox> */}
          <FilterButton onClick={() => filteredAll()}>검색 적용하기</FilterButton>
        </FilterWrap>
      </Wrap>
      <TableBackgroundWrap>
        <TableWrap>
          <FindWorkersTable data={overseasStore.resumesData} />
        </TableWrap>
        <PaginateWrap>
          <Pagination
            totalPage={Math.ceil(overseasStore.totalResumes / listPerPage)}
            limit={10}
            page={page}
            setPage={setPage}
          ></Pagination>
        </PaginateWrap>
      </TableBackgroundWrap>
      <Footer />
    </>
  );
});

const Wrap = styled.div`
  margin: 80px auto 240px;
  width: 1100px;
`;

const Title = styled.p`
  font-size: 28px;
  letter-spacing: -1.75px;
  font-family: 'nexonBold';
  margin: 0 0 10px 10px; ;
`;

const FilterWrap = styled.div``;

const FilterBox = styled.div`
  border: 2px solid ${theme.colors.border};
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  & + & {
    margin: 23px 0 0 0;
  }
`;

const FilterTitle = styled.div`
  font-size: 24px;
  font-family: 'nexonBold';
  color: ${theme.colors.bodyText};
  width: 210px;
  padding: 25px 0 0 22px;
  display: flex;
  background-color: #e8e8f6;
  border-radius: 10px 0 0 10px;
  letter-spacing: -1.5px;
`;

const Icon = styled.div`
  color: ${theme.colors.primary};
  margin: 0 5px 0 0;
`;

const FilterItems = styled.div`
  padding: 25px 15px;
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 300px);
`;

const FilterItem = styled.div`
  font-size: 16px;
  font-family: 'nexonRegular';
  padding: 6px 15px;
  border-radius: 30px;
  border: 3px solid #e8e8f6;
  margin: 10px 8px;

  ${(props) =>
    props.active === true &&
    css`
      border: 3px solid ${theme.colors.primary};
      color: ${theme.colors.primary};
    `}
`;

const FilterButton = styled.button`
  width: 240px;
  height: 61px;
  border: 2px solid ${theme.colors.primary};
  font-size: 22px;
  color: ${theme.colors.bodyText};
  font-family: 'nexonRegular';
  margin: 36px auto 0;
  display: block;
  border-radius: 10px;
  background-color: white;
`;

const TableBackgroundWrap = styled.div`
  margin: 87px 0 0 0;
  padding: 100px 0;
  background-color: #eef1f5;
  min-width: 1100px;
`;

const TableWrap = styled.div`
  width: 1100px;
  margin: 0 auto;
`;

const PaginateWrap = styled.div`
  margin: 50px 0;
`;

export default FindWorkers;
