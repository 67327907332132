import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';

import { GrFormClose } from 'react-icons/gr';

const PopUp = ({ children, visible, onClose }) => {
  if (!visible) return null;
  return (
    <>
      <DarkBackground />
      <BasicPopUp>
        <TopSection>
          <CloseButton onClick={onClose} />
        </TopSection>
        <ContentsWrap>{children}</ContentsWrap>
      </BasicPopUp>
    </>
  );
};
export default PopUp;

const DarkBackground = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.3;
  z-index: 99;
`;

const PopupContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -450px;
  margin-top: -250px;
  width: 900px;
  padding: 0;
  background-color: #fff;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${(props) =>
    props.type === 'success' &&
    css`
      text-align: center;
    `}
`;
const BasicPopUp = styled(PopupContainer)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media ${theme.mobile} {
    padding-bottom: 36px;
  }
`;

const ContentsWrap = styled.div`
  padding: 30px 80px;
  overflow-y: hidden;
`;

const TopSection = styled.div`
  width: 100%;
  height: 33px;
  background-color: #eef1f5;
  display: flex;
  justify-content: flex-end;
`;

const CloseButton = styled(GrFormClose)`
  font-size: 30px;
  color: 434343;
  cursor: pointer;
`;
