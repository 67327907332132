import axios from 'axios';

export default function setAuthToken(token) {
  axios.defaults.headers.common['X-KOLIFE-OVR-AUTH-TOKEN'] = '';
  delete axios.defaults.headers.common['X-KOLIFE-OVR-AUTH-TOKEN'];

  if (token) {
    axios.defaults.headers.common['X-KOLIFE-OVR-AUTH-TOKEN'] = `${token}`;
  }
}
