import { postUsersApi, postUsersLoginApi, postFindPasswordApi, postResetPasswordApi } from 'api/API';
import { makeAutoObservable, runInAction } from 'mobx';
import setAuthToken from 'api/setAuthToken';

class AuthStore {
  constructor() {
    makeAutoObservable(this);
  }

  postUser = async (data) => {
    await postUsersApi(data)
      .then((res) => {
        console.log(res);
        alert('가입 요청에 성공했습니다.')
        window.location.href = '/login';
        
      })
      .catch((error) => {
        if (409 === error.response.status) {
          alert('이미 존재하는 유저 정보 입니다.');
        } else {
          alert('요청에 실패했습니다.');
        }
      });
  };

  postUserLogin = async (data) => {
    await postUsersLoginApi(data)
      .then((res) => {
        if (res.data.responseCode === 'SUCCESS') {
          setAuthToken(res.data.data.accessToken);
          window.location.href = '/';
          localStorage.setItem('id', res.data.data.id);
          localStorage.setItem('role', res.data.data.role);
          localStorage.setItem('token', res.data.data.accessToken);
        }
        console.log(res.data.data.accessToken);
      })
      .catch((error) => {
        console.log(error);
        alert('요청에 실패했습니다.');
      });
  };

  postUserLogout = () => {
    setAuthToken(null);
    localStorage.clear(); 
    window.location.href = '/';
  }

  postFindPassword = async (data) => {
    await postFindPasswordApi(data)
      .then((res) => {
        console.log(res);
        if (res.data.responseCode !== 'SUCCESS') {
          alert(res.data.message);
          return;
        }
        alert('비밀번호 재설정을 위한 링크가 발송되었습니다.');

      })
      .catch((error) => {
        console.log(error);
        alert('요청에 실패했습니다.');
      });
  };

  postResetPassword = async (data) => {
    await postResetPasswordApi(data)
      .then((res) => {
        console.log(res);
        if (res.data.responseCode !== 'SUCCESS') {
          alert(res.data.message);
          return;
        }
        alert('정상적으로 처리되었습니다.');

        window.location.href = '/login';
      })
      .catch((error) => {
        console.log(error);
        alert('요청에 실패했습니다.');
      });
  };
}

const authStore = new AuthStore();

export default authStore;
