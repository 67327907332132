import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import Navbar from 'components/templates/Navbar';
import Footer from 'components/templates/Footer';
import { observer } from 'mobx-react';
import stores from 'stores';
import { useNavigate } from "react-router-dom"

const Login = observer(() => {
  const { authStore } = stores;
  const navigate = useNavigate();
  const [coworkerInputs, setCoworkerInputs] = useState({
    coworkerId: '',
    coworkerPassword: '',
  });

  const { coworkerId, coworkerPassword } = coworkerInputs;

  const [coworkerLoginKeep, setCoworkerLoginKeep] = useState(false);

  const [companyInputs, setCompanyInputs] = useState({
    companyId: '',
    companyPassword: '',
  });

  const { companyId, companyPassword } = companyInputs;
  const [companyLoginKeep, setCompanyLoginKeep] = useState(false);

  const onChangeCoworker = (e) => {
    const { value, name } = e.target;
    setCoworkerInputs({
      ...coworkerInputs,
      [name]: value,
    });
  };

  const onChangeCompany = (e) => {
    const { value, name } = e.target;
    setCompanyInputs({
      ...companyInputs,
      [name]: value,
    });
  };

  const handleSubmitCoworkerLogin = (e) => {
    e.preventDefault();
    const data = {
      email: coworkerId,
      password: coworkerPassword,
      role: 'EMPLOYEE',
    };
    if (!coworkerId) {
      return;
    }
    if (!coworkerPassword) {
      return;
    }
    authStore.postUserLogin(data);
  };

  const handleSubmitCompanyLogin = (e) => {
    e.preventDefault();
    const data = {
      email: companyId,
      password: companyPassword,
      role: 'EMPLOYER',
    };
    if (!companyId) {
      return;
    }
    if (!companyPassword) {
      return;
    }
    authStore.postUserLogin(data);
  };

  return (
    <>
      <Navbar />
      <FlexWrap>
        <Wrap>
          <Title>회원 로그인</Title>
          <LoginWrap>
            <LoginBox>
              <LoginFormTitle>구직자 로그인 하기</LoginFormTitle>
              <SignUpButtonWrap>
                <SignUpButton onClick={() => navigate('/signUp/coworker')}>회원가입 하기</SignUpButton>
              </SignUpButtonWrap>
              <LoginForm onSubmit={handleSubmitCoworkerLogin}>
                <InputWrap>
                  <Input
                    placeholder="아이디"
                    name="coworkerId"
                    value={coworkerId}
                    onChange={onChangeCoworker}
                    autoComplete="new-password" 
                  />
                  <Input
                    placeholder="비밀번호"
                    name="coworkerPassword"
                    value={coworkerPassword}
                    onChange={onChangeCoworker}
                    type="password"
                    autoComplete="new-password" 
                  />
                </InputWrap>
                <LoginButton type="submit">로그인</LoginButton>
              </LoginForm>
              <ButtonWrap>
                <KeepLoginButton
                  onClick={() => setCoworkerLoginKeep(!coworkerLoginKeep)}
                  active={coworkerLoginKeep}
                >
                  로그인 상태 유지
                </KeepLoginButton>
                <FindPasswordButton onClick={() => navigate('/PasswordResetCoworker')}>비밀번호 찾기</FindPasswordButton>
              </ButtonWrap>
            </LoginBox>
            <LoginBox>
              <LoginFormTitle>기업 로그인 하기</LoginFormTitle>
              <SignUpButtonWrap>
                <SignUpButton onClick={() => navigate('/signUp/company')}>회원가입 하기</SignUpButton>
              </SignUpButtonWrap>
              <LoginForm onSubmit={handleSubmitCompanyLogin}>
                <InputWrap>
                  <Input
                    placeholder="아이디"
                    name="companyId"
                    value={companyId}
                    onChange={onChangeCompany}
                    autoComplete="new-password" 
                  />
                  <Input
                    placeholder="비밀번호"
                    name="companyPassword"
                    value={companyPassword}
                    onChange={onChangeCompany}
                    type="password"
                    autoComplete="new-password" 
                  />
                </InputWrap>
                <LoginButton type="submit">로그인</LoginButton>
              </LoginForm>
              <ButtonWrap>
                <KeepLoginButton
                  onClick={() => setCompanyLoginKeep(!companyLoginKeep)}
                  active={companyLoginKeep}
                >
                  로그인 상태 유지
                </KeepLoginButton>
                <FindPasswordButton onClick={() => navigate('/PasswordResetCompany')}>비밀번호 찾기</FindPasswordButton>
              </ButtonWrap>
            </LoginBox>
          </LoginWrap>
        </Wrap>
      </FlexWrap>
      <Footer />
    </>
  );
});

const FlexWrap = styled.div`
  width: 100vw;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrap = styled.div`
  width: 1078px;
`;

const Title = styled.p`
  font-size: 28px;
  font-family: 'nexonBold';
  text-align: center;
`;

const LoginWrap = styled.div`
  border: 2px solid ${theme.colors.border};
  border-radius: 10px;
  width: 1074px;
  height: 426px;
  margin: 70px 0 0 0;
  display: flex;
  align-items: center;
`;

const LoginBox = styled.div`
  width: 50%;
  height: 395px;
  padding: 38px 60px 0 60px;
  box-sizing: border-box;
  & + & {
    border-left: 2px solid ${theme.colors.border};
  }
`;

const LoginFormTitle = styled.p`
  font-size: 21px;
  color: #242d24;
  font-family: 'nexonMedium';
`;

const LoginForm = styled.form`
  display: flex;
`;

const InputWrap = styled.div`
  width: 280px;
`;

const Input = styled.input`
  width: 280px;
  height: 60px;
  border: 2px solid ${theme.colors.borderLight};
  font-size: 18px;
  padding: 0 0 0 22px;

  & + & {
    border-top: none;
  }

  &::placeholder {
    color: #b2b3c7;
  }
`;

const LoginButton = styled.button`
  height: 120px;
  width: 140px;
  border: none;
  background-color: ${theme.colors.primary};
  color: white;
  font-family: 'nexonMedium';
  font-size: 18px;
`;

const SignUpButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 70px 0 10px 0;
`;

const SignUpButton = styled.button`
  background-color: transparent;
  border: none;
  color: #242d24;
  font-size: 15px;
  padding: 0;
  font-family: 'nexonMedium';
`;

const ButtonWrap = styled(SignUpButtonWrap)`
  justify-content: space-between;
  margin: 10px 0 0 0;
`;

const KeepLoginButton = styled(SignUpButton)`
  ${(props) =>
    props.active === true &&
    css`
      color: ${theme.colors.primary};
    `}
`;
const FindPasswordButton = styled(SignUpButton)``;

export default Login;
