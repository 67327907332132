import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import { sliceArrayByLimit } from 'components/utils/useful-function';

import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';

const Pagination = ({ totalPage, limit, page, setPage }) => {
  const [currentPageArray, setCurrentPageArray] = useState([]);
  const [totalPageArray, setTotalPageArray] = useState([]);

  useEffect(() => {
    if (page % limit === 1) {
      setCurrentPageArray(totalPageArray[Math.floor(page / limit)]);
    } else if (page % limit === 0) {
      setCurrentPageArray(totalPageArray[Math.floor(page / limit) - 1]);
    }
  }, [page]);

  console.log(totalPage, 'totalPage');

  useEffect(() => {
    const slicedPageArray = sliceArrayByLimit(totalPage, limit);
    setTotalPageArray(slicedPageArray);
    setCurrentPageArray(slicedPageArray[0]);
  }, [totalPage]);

  return (
    <div>
      <nav>
        <PageUl>
          <Prev onClick={() => setPage(page - 1)} disabled={page === 1}>
            <Icon>
              <MdOutlineKeyboardArrowLeft />
            </Icon>
            이전
          </Prev>
          {currentPageArray?.map((i) => (
            <PageLi key={i + 1} onClick={() => setPage(i + 1)} active={page === i + 1}>
              {i + 1}
            </PageLi>
          ))}
          <Next onClick={() => setPage(page + 1)} disabled={page === totalPage}>
            다음
            <Icon>
              <MdOutlineKeyboardArrowRight />
            </Icon>
          </Next>
        </PageUl>
      </nav>
    </div>
  );
};

const PageUl = styled.ul`
  list-style: none;
  text-align: center;
  border-radius: 3px;
  font-family: 'nexonBord';
  display: flex;
  justify-content: center;
`;

const PageLi = styled.li`
  font-size: 22px;
  cursor: pointer;
  margin: 0 15px;
  color: #4d4d4d;

  ${(props) =>
    props.active === true &&
    css`
      color: ${theme.colors.primary};
    `}
`;

const PageSpan = styled.span``;

const Prev = styled.button`
  font-size: 22px;
  color: #9c9eb9;
  margin: 0 40px 0 0;
  display: flex;
  cursor: pointer;
`;

const Next = styled(Prev)`
  margin: 0 0 0 40px;
`;

const Icon = styled.div``;

export default Pagination;
