import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import Navbar from 'components/templates/Navbar';
import Footer from 'components/templates/Footer';
import './slick.css';
import './slick-theme.css';
import { FaMapMarkerAlt, FaHashtag } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import PopUp from 'components/atoms/PopUp';
import FindWorkersTable from 'components/atoms/FindWorkersTable';
import Pagination from 'components/atoms/Pagination';
import { useNavigate } from "react-router-dom";

import { observer } from 'mobx-react';
import stores from 'stores';
import { toJS } from 'mobx';

const Main = observer(() => {
  const navigate = useNavigate();
  const { overseasStore, counrtyStore } = stores;

  const [page, setPage] = useState(1);
  const listPerPage = 12;

  useEffect(() => {
    counrtyStore.getCountries();
  }, [counrtyStore]);

  useEffect(() => {
    overseasStore.getResume('', '', '', '', '', page - 1, listPerPage, '');
  }, [overseasStore, page, listPerPage]);

  const [languageData, setLanguageData] = useState([]);

  useEffect(() => {
    let filteredLanguageData = counrtyStore.countryData
    .filter((item) => item.countryIsoCode !== 'etc')
    .filter((item) => item.countryIsoCode !== 'kr')
    .filter((item) => item.countryIsoCode !== 'us')
    .filter((item) => item.countryIsoCode !== 'gb')
    .filter((item) => item.countryIsoCode !== 'ca')
    .filter((item) => item.countryIsoCode !== 'au');
    filteredLanguageData = [...filteredLanguageData, 
      {
        countryIsoCode: 'en',
        nameInNative: 'English',
        flagImgUrl: 'https://kotrip.s3.amazonaws.com/country/flag_glob_2.png',
      }
    ];
    setLanguageData(
      toJS(filteredLanguageData)
    );
  }, [counrtyStore.countryData]);

  const educationData = [
    { value: 'HIGH_SCHOOL_GRADUATE', text: '고등학교 졸업' },
    { value: 'COLLEGE_GRADUATE', text: '대학 졸업' },
    { value: 'KOREA_COLLEGE_GRADUATE', text: '국내 대학 졸업' },
    { value: 'TOP_50_COLLEGE_GRADUATE', text: '세계 500대 대학 졸업' },
    { value: 'MASTER', text: '석사' },
    { value: 'DOCTOR', text: '박사' },
  ];

  const careerData = [
    { value: 'ONE', text: '1년' },
    { value: 'TWO', text: '2년' },
    { value: 'THREE', text: '3년' },
    { value: 'FIVE', text: '5년' },
    { value: 'EIGHT', text: '8년' },
    { value: 'TEN', text: '10년' },
    { value: 'TEN_ABOVE', text: '10년 이상' },
  ];

  const koreanSkillData = [
    { value: 'VERY_BAD', text: '최하' },
    { value: 'BAD', text: '하' },
    { value: 'NORMAL', text: '중' },
    { value: 'GOOD', text: '상' },
    { value: 'VERY_GOOD', text: '최상' },
  ];

  const [nationalCheckList, setNationalCheckList] = useState('');

  const handleClickNationalList = (item) => {
    setNationalCheckList(item);
  };

  const [educationCheckList, setEducationCheckList] = useState('');

  const handleClickEducationList = (item) => {
    setEducationCheckList(item);
  };

  const [careerCheckList, setCareerCheckList] = useState('');

  const handleClickCareerList = (item) => {
    setCareerCheckList(item);
  };

  const [koreanSkillCheckList, setKoreanSkillCheckList] = useState('');

  const handleClickKoreanSkillList = (item) => {
    setKoreanSkillCheckList(item);
  };

  const [koreanCapabilityCertificate, setKoreanCapabilityCertificate] = useState(false);

  const handleClickKoreanCapabilityCertificate = () => {
    setKoreanCapabilityCertificate(!koreanCapabilityCertificate);
  };

  const filteredAll = () => {
    overseasStore.getResume(
      educationCheckList,
      nationalCheckList,
      '',
      koreanSkillCheckList,
      koreanCapabilityCertificate,
      page - 1,
      listPerPage,
      careerCheckList,
    );
  };

  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('countryIsoCode'));

  const selectLanguage = (countryIsoCode, langCode) => {
    setSelectedLanguage(countryIsoCode);
    console.log(countryIsoCode);
    localStorage.setItem('countryIsoCode', countryIsoCode);
    localStorage.setItem('languageCode', langCode);
  };

  const [popupVisible, setPopupVisible] = useState(false);

  const handleClickPopup = () => {
    if (!selectedLanguage) {
      return;
    }
    setPopupVisible(!popupVisible);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  return (
    <>
      <Navbar />
      <TopSection>
        <MenuWrapFlex>
          <MenuWrap>
            <MenuLink 
                onClick={() => {
                  if (localStorage.getItem('role') !== 'EMPLOYER') {
                    alert('기업으로 로그인 후 조회가능한 페이지 입니다.')
                    navigate(`/login`)
                  } else {
                    navigate(`/findWorkers`)
                  }
                }}>
              <MenuItem>인재 찾기</MenuItem>
            </MenuLink>
            <MenuLink onClick={() => {
                if (localStorage.getItem('role') !== 'EMPLOYEE') {
                  alert('구직자로 로그인 후 조회가능한 페이지 입니다.')
                  navigate(`/login`)
                } else {
                  navigate(`/resumeRegistration`)
                }
              }}>
              <MenuItem>이력서 작성하기</MenuItem>
            </MenuLink>
            <MenuATag href="https://ko-life.co.kr/" target="_black">
              <MenuItem>코라이프 홈페이지</MenuItem>
            </MenuATag>
          </MenuWrap>
        </MenuWrapFlex>
      </TopSection>
      <MiddleSection>
        <Title>해외인재 상세 검색</Title>
        <FilterWrap>
          <FilterBox>
            <FilterTitle>
              <Icon>
                <FaMapMarkerAlt />
              </Icon>
              국적 검색
            </FilterTitle>
            <FilterItems>
              {counrtyStore.countryData.map((item) => (
                <FilterItem
                  onClick={() => handleClickNationalList(item.countryIsoCode)}
                  active={nationalCheckList === item.countryIsoCode}
                  key={item.countryIsoCode}
                >
                  {item.name}
                </FilterItem>
              ))}
            </FilterItems>
          </FilterBox>
          <FilterBox>
            <FilterTitle>
              <Icon>
                <FaHashtag />
              </Icon>
              학력 검색
            </FilterTitle>
            <FilterItems>
              {educationData.map((item) => (
                <FilterItem
                  onClick={() => handleClickEducationList(item.value)}
                  active={educationCheckList === item.value}
                  key={item.value}
                >
                  {item.text}
                </FilterItem>
              ))}
            </FilterItems>
          </FilterBox>
          <FilterBox>
            <FilterTitle>
              <Icon>
                <FaHashtag />
              </Icon>
              경력 검색
            </FilterTitle>
            <FilterItems>
              {careerData.map((item) => (
                <FilterItem
                  onClick={() => handleClickCareerList(item.value)}
                  active={careerCheckList === item.value}
                  key={item.value}
                >
                  {item.text}
                </FilterItem>
              ))}
            </FilterItems>
          </FilterBox>
          <FilterBox>
            <FilterTitle>
              <Icon>
                <FaHashtag />
              </Icon>
              한국어 검색
            </FilterTitle>
            <FilterItems>
              {koreanSkillData.map((item) => (
                <FilterItem
                  onClick={() => handleClickKoreanSkillList(item.value)}
                  active={koreanSkillCheckList === item.value}
                  key={item.value}
                >
                  {item.text}
                </FilterItem>
              ))}
              <FilterItem
                onClick={() => handleClickKoreanCapabilityCertificate()}
                active={koreanCapabilityCertificate}
              >
                한국어 자격증 보유
              </FilterItem>
            </FilterItems>
          </FilterBox>
          <FilterButton onClick={() => filteredAll()}>검색 적용하기</FilterButton>
        </FilterWrap>
      </MiddleSection>

      <TableBackgroundWrap>
        <TableWrap>
          <FindWorkersTable data={overseasStore.resumesData} />
        </TableWrap>
        <PaginateWrap>
          <Pagination
            totalPage={Math.ceil(overseasStore.totalResumes / listPerPage)}
            limit={10}
            page={page}
            setPage={setPage}
          ></Pagination>
        </PaginateWrap>
      </TableBackgroundWrap>
      <Footer />
      <PopUp visible={popupVisible} onClose={handleClosePopup}>
        <PopUpTitle>사용자 언어 선택</PopUpTitle>
        <PopUpTab>
          {languageData.map((item) => (
            <PopUpLanguageItemWrap
              onClick={() => selectLanguage(item.countryIsoCode, item.langCode)}
              key={item.countryIsoCode}
            >
              <PopUpLanguageItem
                tab={selectedLanguage === item.countryIsoCode}>
                <Flag>
                  <img src={item.flagImgUrl} alt="flag" />
                </Flag>
                <FlagText langCode={item.langCode}>{item.nameInNative}</FlagText>
              </PopUpLanguageItem>
            </PopUpLanguageItemWrap>
          ))}
        </PopUpTab>
        <PopUpButton onClick={handleClickPopup}>확인</PopUpButton>
      </PopUp>
    </>
  );
});

const TopSection = styled.div`
  margin: 110px auto 0;
  width: 1100px;
  justify-content: center;
  display: flex;
`;

const MenuWrapFlex = styled.div`
  display: flex;
`;

const MenuWrap = styled.div`
  display: flex;
  margin: 34px 0 0 8px;
`;

const MenuLink = styled.button`
  & + & {
    margin: 0 0 0 23px;
  }
`;

const MenuItem = styled.button`
  width: 242px;
  height: 77px;
  border-radius: 10px;
  background-color: #555666;
  font-family: 'nexonBold';
  font-size: 20px;
  letter-spacing: -1.25px;
  color: white;
`;

const MenuATag = styled.a`
  margin: 0 0 0 23px;
`;

const PopUpTitle = styled.p`
  text-align: center;
  font-family: 'nexonRegular';
  font-size: 25px;
`;

const PopUpTab = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  margin: 70px 0 0 0;
  overflow-y: hidden;
`;

const PopUpLanguageItemWrap = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const PopUpLanguageItem = styled.div`
  text-align: center;
  padding: 12px;

  ${(props) =>
    props.tab === true &&
    css`
      background-color: rgba(7, 0, 133, 0.2);
      border-radius: 5px;
    `}
`;

const Flag = styled.div`
  width: 30px;
  height: 25px;
  margin: 0 auto 25px;

  & > img {
    width: 30px;
    object-fit: cover;
  }
`;

const PopUpButton = styled.button`
  width: 128px;
  border-radius: 5px;
  height: 48px;
  border: 2px solid ${theme.colors.primary};
  margin: 20px auto;
  color: ${theme.colors.primary};
  background-color: white;
  font-size: 20px;
  display: block;
`;

const FlagText = styled.p`
  font-size: 15px;
  line-height: 20px;
  font-family: 'nexonRegular';
  ${(props) =>
    props.langCode === 'th' &&
    css`
    font-family: 'PromptR';
  `}
  ${(props) =>
    props.langCode === 'vi' &&
    css`
    font-family: 'ArchivoR';
  `}
  ${(props) =>
    ['ru', 'mn', 'km'].includes(props.langCode) &&
    css`
    font-family: 'NotoSansR';
  `}
  ${(props) =>
    ['zh-TW', 'zh-CN'].includes(props.langCode) &&
    css`
    font-family: 'NotoSansCJKTCR';
  `}
`;

const MiddleSection = styled.div`
  margin: 125px auto 0;
  width: 1100px;
`;

const Title = styled.p`
  font-size: 28px;
  letter-spacing: -1.75px;
  font-family: 'nexonBold';
  margin: 0 0 10px 10px; ;
`;

const FilterWrap = styled.div``;

const FilterBox = styled.div`
  border: 2px solid ${theme.colors.border};
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  & + & {
    margin: 23px 0 0 0;
  }
`;

const FilterTitle = styled.div`
  font-size: 24px;
  font-family: 'nexonBold';
  color: ${theme.colors.bodyText};
  width: 210px;
  padding: 25px 0 0 22px;
  display: flex;
  background-color: #e8e8f6;
  border-radius: 10px 0 0 10px;
  letter-spacing: -1.5px;
`;

const Icon = styled.div`
  color: ${theme.colors.primary};
  margin: 0 5px 0 0;
`;

const FilterItems = styled.div`
  padding: 25px 15px;
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 300px);
`;

const FilterItem = styled.div`
  font-size: 16px;
  font-family: 'nexonRegular';
  padding: 6px 15px;
  border-radius: 30px;
  border: 3px solid #e8e8f6;
  margin: 10px 8px;

  ${(props) =>
    props.active === true &&
    css`
      border: 3px solid ${theme.colors.primary};
      color: ${theme.colors.primary};
    `}
`;

const FilterButton = styled.button`
  width: 240px;
  height: 61px;
  border: 2px solid ${theme.colors.primary};
  font-size: 22px;
  color: ${theme.colors.bodyText};
  font-family: 'nexonRegular';
  margin: 36px auto 0;
  display: block;
  border-radius: 10px;
  background-color: white;
`;

const TableBackgroundWrap = styled.div`
  margin: 87px 0 0 0;
  padding: 100px 0;
  background-color: #eef1f5;
  min-width: 1100px;
`;

const TableWrap = styled.div`
  width: 1100px;
  margin: 0 auto;
`;

const PaginateWrap = styled.div`
  margin: 50px 0;
`;

export default Main;
