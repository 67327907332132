import { updateFileApi } from 'api/API';
import { makeAutoObservable, runInAction } from 'mobx';

class FileStore {
  constructor() {
    makeAutoObservable(this);
  }

  updateFile = async (data) => {
    return updateFileApi(data);
  };
}

const fileStore = new FileStore();

export default fileStore;
