import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import Navbar from 'components/templates/Navbar';
import Footer from 'components/templates/Footer';
import { observer } from 'mobx-react';
import stores from 'stores';

const CoworkerSignUp = observer(() => {
  const { authStore } = stores;
  const [inputs, setInputs] = useState({
    name: '',
    email: '',
    password: '',
    passwordCheck: '',
  });

  const { name, email, password, passwordCheck } = inputs;

  const onChange = (e) => {
    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const [passwordError, setPasswordError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name) {
      return;
    }
    if (!password) {
      return;
    }
    if (!email) {
      return;
    }
    if (!passwordCheck) {
      return;
    }
    if (password !== passwordCheck) {
      setPasswordError(true);
      return;
    }
    const data = {
      email: email,
      name: name,
      password: password,
      role: 'EMPLOYEE',
    };
    authStore.postUser(data);
    console.log(name, email, password);
  };

  return (
    <>
      <Navbar />
      <FlexWrap>
        <Wrap>
          <Title>구직자 회원가입 하기</Title>
          <SignUpForm onSubmit={handleSubmit}>
            <InputBox>
              <InputLabel>성명</InputLabel>
              <Input
                placeholder="성함을 입력해주세요."
                name="name"
                value={name}
                onChange={onChange}
              />
            </InputBox>
            <InputBox>
              <InputLabel>이메일 입력</InputLabel>
              <Input
                placeholder="사용하시는 이메일을 입력해주세요."
                name="email"
                value={email}
                onChange={onChange}
              />
            </InputBox>
            <InputBox>
              <InputLabel>비밀번호</InputLabel>
              <Input
                placeholder="비밀번호 (8 ~ 16자 이상)"
                name="password"
                value={password}
                onChange={onChange}
                type="password"
              />
            </InputBox>
            <InputBox>
              <InputLabel>비밀번호 확인</InputLabel>
              <Input
                placeholder="비밀번호 (8 ~ 16자 이상)"
                name="passwordCheck"
                value={passwordCheck}
                onChange={onChange}
                type="password"
              />
              {passwordError && <Error>비밀번호가 일치하지 않습니다</Error>}
            </InputBox>
            <Button type="submit">가입하기</Button>
          </SignUpForm>
        </Wrap>
      </FlexWrap>
      <Footer />
    </>
  );
});

const FlexWrap = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
`;

const Wrap = styled.div`
  width: 914px;
  margin: 150px 0 300px 0;
`;

const Title = styled.p`
  font-size: 35px;
  font-family: 'nexonBold';
  text-align: center;
`;

const SignUpForm = styled.form`
  border: 2px solid ${theme.colors.border};
  border-radius: 10px;
  width: 914px;
  height: 980px;
  margin: 30px 0 0 0;
  padding: 80px 77px;
  box-sizing: border-box;
`;

const InputBox = styled.div`
  & + & {
    margin: 50px 0 0 0;
  }
`;

const InputLabel = styled.p`
  font-family: 'nexonMedium';
  font-size: 20px;
  color: #242d24;
  margin: 0 0 14px 0;
`;
const Input = styled.input`
  width: 758px;
  height: 76px;
  border: 2px solid ${theme.colors.borderLight};
  font-size: 22px;
  padding: 0 0 0 30px;
  font-family: 'nexonRegular';

  &::placeholder {
    color: #b2b3c7;
  }
`;

const Button = styled.button`
  border: none;
  width: 373px;
  height: 68px;
  background-color: ${theme.colors.primary};
  color: white;
  font-size: 22px;
  font-family: 'nexonMedium';
  margin: 115px auto 0;
  display: block;
`;

const Error = styled.p`
  font-size: 14px;
  color: ${theme.colors.error};
  margin: 10px 0 0 0;
`;
export default CoworkerSignUp;
