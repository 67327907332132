import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import SkillBox from './SkillBox';
import { Link, useNavigate } from "react-router-dom";


const FindWorkersTable = ({ data }) => {
  const navigate = useNavigate();
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  return (
    <TableWrap>
      <Tbody>
        {data.map((item) => (
          <TbodyTr key={item?.id}>
            <NationalityTd>
              <NationalTdItem>
                <Flag>
                  <img src={item?.country?.flagImgUrl} alt="flag" />
                </Flag>
                <p>{item?.country?.name}</p>
              </NationalTdItem>
            </NationalityTd>
            <GenderTd>
              <p>{item?.gender === 'MAN' ? '남성' : 'WOMAN' ? '여성' : '알 수 없음'}</p>
            </GenderTd>
            <AgeTd>
              <p>{item?.birthDate?.substr(0, 4)}년생</p>
            </AgeTd>
            <EducationTd>
              <p>
                {item?.academicList && (item?.academicList[0]?.academicBackgroundType === 'HIGH_SCHOOL_GRADUATE'
                  ? '고등학교 졸업'
                  : item?.academicList[0]?.academicBackgroundType === 'COLLEGE_GRADUATE'
                  ? '대학 졸업'
                  : item?.academicList[0]?.academicBackgroundType === 'KOREA_COLLEGE_GRADUATE'
                  ? '국내대학 졸업'
                  : item?.academicList[0]?.academicBackgroundType === 'TOP_50_COLLEGE_GRADUATE'
                  ? 'TOP 50 대학 졸업'
                  : item?.academicList[0]?.academicBackgroundType === 'MASTER'
                  ? '석사'
                  : item?.academicList[0]?.academicBackgroundType === 'DOCTOR'
                  ? '박사'
                  : '-')}
              </p>
            </EducationTd>
            <KoreanSkillTd>
              <KoreanSkillTdItem>
                <p>한국어 능력</p>
                <SkillBox skills={item?.koreanCapability} />
              </KoreanSkillTdItem>
            </KoreanSkillTd>
            <CareerTd>
              <p>
                경력{' '}
                {item?.careerList
                  ? item.careerList[0]?.workExperienceType === 'ONE'
                    ? '1년'
                    : item.careerList[0]?.workExperienceType === 'TWO'
                    ? '2년'
                    : item.careerList[0]?.workExperienceType === 'THREE'
                    ? '3년'
                    : item.careerList[0]?.workExperienceType === 'FIVE'
                    ? '5년'
                    : item.careerList[0]?.workExperienceType === 'EIGHT'
                    ? '8년'
                    : item.careerList[0]?.workExperienceType === 'TEN'
                    ? '10년'
                    : item.careerList[0]?.workExperienceType === 'TEN ABOVE'
                    ? '10년 이상'
                    : '-'
                  : '-'}
              </p>
            </CareerTd>
            <HopeWorKTd>
              <p>{item?.expectedJobs.join('/')}</p>
            </HopeWorKTd>
            <HopeSalaryTd>
              <HopeSalaryTdItem>
                <p>￦ {numberWithCommas(item?.expectedPayAmount)}</p>
                <ResumeButton onClick={() => {
                  if (localStorage.getItem('role') !== 'EMPLOYER' && localStorage.getItem('id') != item.user.id) {
                    alert('기업으로 로그인 후 조회가능한 페이지 입니다.')
                    navigate(`/login`)
                  } else {
                    navigate(`/resumeView/${item.id}`)
                  }
                }}>이력서 보기</ResumeButton>
              </HopeSalaryTdItem>
            </HopeSalaryTd>
          </TbodyTr>
        ))}
      </Tbody>
    </TableWrap>
  );
};

const TableWrap = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  position: relative;
`;

const Tbody = styled.tbody`
  font-size: 15px;
  font-family: 'nexonRegular';
  background-color: white;
`;
const TbodyTr = styled.tr`
  height: 75px;
  & + & {
    border-top: 5px solid #eef1f5;
  }
`;
const NationalityTd = styled.td`
  width: 120px;
  height: 75px;
  & > p {
    text-align: center;
  }
`;
const NationalTdItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const GenderTd = styled.td`
  border-left: 5px solid #eef1f5;
  width: 70px;
  & > p {
    text-align: center;
  }
`;
const AgeTd = styled.td`
  border-left: 5px solid #eef1f5;
  width: 100px;
  & > p {
    text-align: center;
    margin: 0;
  }
`;
const EducationTd = styled.td`
  border-left: 5px solid #eef1f5;
  width: 100px;

  & > p {
    text-align: center;
    margin: 0;
  }
`;
const CareerTd = styled.td`
  border-left: 5px solid #eef1f5;
  width: 80px;

  & > p {
    text-align: center;
    margin: 0;
  }
`;
const KoreanSkillTd = styled.td`
  border-left: 5px solid #eef1f5;
  width: 160px;
`;

const KoreanSkillTdItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > p {
    margin: 2px 10px 0 0;
  }
`;

const HopeWorKTd = styled.td`
  border-left: 5px solid #eef1f5;
  width: 100px;

  & > p {
    text-align: center;
    margin: 0;
    white-space: nowrap;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
const HopeSalaryTd = styled.td`
  border-left: 5px solid #eef1f5;
  width: 150px;
`;

const HopeSalaryTdItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > p {
    text-align: left;
    margin: 0 0 0 10px;
    width: 110px;
  }
`;

const ResumeButton = styled.button`
  background-color: transparent;
  width: 108px;
  height: 41px;
  border: 4px solid ${theme.colors.primary};
  color: ${theme.colors.bodyText};
  font-size: 15px;
  border-radius: 30px;
  font-family: 'nexonMedium';
  margin: 0 20px 0 40px;
`;

const Icon = styled.div`
  position: absolute;
  right: 10px;
  top: 27px;
`;

const Flag = styled.div`
  width: 30px;
  height: 20px;
  margin: 0 20px 0 0;

  & > img {
    width: 30px;
    height: 20px;
    object-fit: cover;
  }
`;

export default FindWorkersTable;
