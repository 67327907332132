const media = {
  mobileS: '320px',
  mobile: '576px',
  tablet: '820px',
  laptop: '1047px',
  desktop: '1600px',
};

const theme = {
  mobileS: `(max-width: ${media.mobileS})`,
  mobile: `(max-width: ${media.mobile})`,
  tablet: `(max-width: ${media.tablet})`,
  laptop: `(max-width: ${media.laptop})`,
  desktop: `(max-width: ${media.desktop})`,
  colors: {
    primary: '#9937ff',
    bodyText: '#403f49',
    border: '#8d8a9b',
    lightPrimary: '#e8e8f6',
    borderLight: '#b2b3c7',
    error: '#b85565',
  },
};

export default theme;
