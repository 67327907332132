import Main from 'components/pages/Main/Main';
import Recruitment from 'components/pages/Recruitment/Recruitment';
import ResumeView from 'components/pages/Resume/ResumeView';
import ResumeRegistration from 'components/pages/Resume/ResumeRegistration';
import FindWorkers from 'components/pages/FindWorkers/FindWorkers';
import Login from 'components/pages/Sign/Login';
import CoworkerSignUp from 'components/pages/Sign/CoworkerSignUp';
import CompanySignUp from 'components/pages/Sign/CompanySignUp';
import PasswordResetCoworker from 'components/pages/Sign/PasswordResetCoworker';
import PasswordResetCompany from 'components/pages/Sign/PasswordResetCompany';
import ResetPassword from 'components/pages/ResetPassword/ResetPassword';

export const routes = [
  { path: '/', element: <Main /> },
  { path: '/login', element: <Login /> },
  { path: '/signUp/coworker', element: <CoworkerSignUp /> },
  { path: '/signUp/company', element: <CompanySignUp /> },
  { path: '/PasswordResetCoworker', element: <PasswordResetCoworker /> },
  { path: '/PasswordResetCompany', element: <PasswordResetCompany /> },
  { path: '/ResetPassword/:token', element: <ResetPassword /> },
  { path: '/recruitment/:resumeId', element: <Recruitment /> },
  { path: '/resumeView/:resumeId', element: <ResumeView /> },
  { path: '/resumeRegistration', element: <ResumeRegistration /> },
  { path: '/findWorkers', element: <FindWorkers /> },
];
